import React from 'react';
import Pagination from 'react-js-pagination';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import './Pagination3D.css';

const Pagination3D = ({
  currentPage,
  totalElements,
  itemsCountPerPage,
  handlePageChange,
}) => {
  return (
    <Pagination
      firstPageText={
        <i>
          <SkipPreviousIcon fontSize="small" className="svg_icons" />
        </i>
      }
      lastPageText={
        <i>
          <SkipNextIcon fontSize="small" className="svg_icons" />
        </i>
      }
      prevPageText={
        <i>
          <ChevronLeftIcon fontSize="small" className="svg_icons" />
        </i>
      }
      nextPageText={
        <i>
          <ChevronRightIcon fontSize="small" className="svg_icons" />
        </i>
      }
      disabledClass=""
      activePage={currentPage + 1}
      totalItemsCount={totalElements}
      itemsCountPerPage={itemsCountPerPage}
      pageRangeDisplayed={3}
      onChange={handlePageChange}
      itemClass="page-item"
      linkClass="page-link"
    />
  );
};

export default Pagination3D;
