import React, { useEffect, useState } from 'react';
import { getFilteredCategories } from '../../../services/client';

import CreateCategory from './iml/CreateCategory';
import EditCategory from './iml/EditCategory';
import Pagination3D from '../../../common/pagination/Pagination3D';
import Spinner3D from '../../../common/spinner/Spinner3D';
import Button3D from '../../../common/layout/Button3D';
import { NotFoundErrorPage } from '../../../common/error3D';

const CategoryTableAdmin = () => {
  const [loading, isLoading] = useState(false);
  const [editCategoryModel, setEditCategoryModel] = useState({
    showModel: false,
    category: {},
  });
  const [createCategoryModel, setCreateCategoryModel] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [refreshPage, setRefreshPage] = useState(0);

  const handlePageChange = (pageNum) => {
    setPageNumber(pageNum - 1);
  };

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    isLoading(true);
    getFilteredCategories(pageNumber, 'createdAt').then((res) => {
      setCategories(res.data.content);
      setTotalElements(res.data.totalElements);
      setCurrentPage(res.data.number);
      setItemsCountPerPage(res.data.size);
      isLoading(false);
    });
  }, [pageNumber, refreshPage]);

  if (editCategoryModel.showModel) {
    return (
      <EditCategory
        setCategoryEditModel={setEditCategoryModel}
        editCategoryModel={editCategoryModel}
        setRefreshPage={setRefreshPage}
      />
    );
  }

  if (createCategoryModel) {
    return (
      <CreateCategory
        setCreateModel={setCreateCategoryModel}
        createModel={createCategoryModel}
        setRefreshPage={setRefreshPage}
      />
    );
  }

  if (loading) {
    return <Spinner3D />;
  }

  if (totalElements === 0) {
    return (
      <NotFoundErrorPage
        textHeader="There are no category!"
        textButton="Please add a category!"
        onClick={() => setCreateCategoryModel(!createCategoryModel)}
      />
    );
  }

  return (
    <div className="grid grid-cols-7 gap-5 px-16">
      <div className="py-10">
        <Button3D
          variant="admin-main"
          text="Create"
          onClick={() => setCreateCategoryModel(!createCategoryModel)}
        />
      </div>
      <div className="col-span-6 mt-5">
        <div className="flex justify-between">
          <div>Total categories: {totalElements}</div>
          <Pagination3D
            currentPage={currentPage}
            totalElements={totalElements}
            itemsCountPerPage={itemsCountPerPage}
            handlePageChange={handlePageChange}
          />
        </div>
        <table className="mt-3 table-auto w-full">
          <thead>
            <tr className="blue text-center border border-gray-500">
              <th className="w-1/12 border-2 px-3 py-3 border-gray-500">#</th>
              <th className="w-9/12 border-2 px-3 py-3 border-gray-500">
                NAME
              </th>
              <th className="w-1/12 border-2 px-3 py-3 border-gray-500">IMG</th>
              <th className="w-1/12 border-2 px-3 py-3 border-gray-500">
                EDIT
              </th>
            </tr>
          </thead>
          <tbody>
            {categories.map((category, i) => (
              <tr key={i}>
                <td className="text-center border-2 px-3 py-3 border-gray-500">
                  {i + 1 + itemsCountPerPage * pageNumber}
                </td>
                <td className="outline-none border-2 px-3 py-3 border-gray-500">
                  {category.categoryName}
                </td>
                <td className="border-2 px-3 py-3 border-gray-500">
                  <div className="flex justify-center">
                    <img
                      src={category.categoryImageLocation}
                      className="w-12 h-8"
                      alt="laptop"
                    />
                  </div>
                </td>
                <td className="border-2 px-3 py-3 border-gray-500">
                  <div className="flex justify-center">
                    <Button3D
                      variant="edit-table"
                      onClick={() =>
                        setEditCategoryModel({
                          showModel: !editCategoryModel.showModel,
                          category,
                        })
                      }
                    />
                  </div>
                </td>
              </tr>
            ))}
            ;
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CategoryTableAdmin;
