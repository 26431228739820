import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import './Navbar.css';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CategoryIcon from '@material-ui/icons/Category';
import StarsIcon from '@material-ui/icons/Stars';
import { useTranslation } from 'react-i18next';
import LanguageSidebar from './LanguageSidebar';
import LanguageIcon from '@material-ui/icons/Language';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const Sidebar = ({ ShowSideBar, changeCurrency }) => {
  const { t } = useTranslation('common');
  return (
    <div className="overlay-side z-50 text-sm">
      <span className="md:hidden z-50 links-custom-mob">
        <div className="">
          <div className="pt-2 mt-1 flex justify-end h-bor border-b-2 ">
            <ClearRoundedIcon
              onClick={ShowSideBar}
              className="mr-3"
              style={{
                width: '35px',
                height: '35px',
              }}
            />
          </div>
          <div className=" margin-ipxs flex justify-end items-center">
            <RouterLink
              to="/"
              className="blue text-lg mr-3 mb-5 text-xs "
              onClick={ShowSideBar}
            >
              {t('home')}
              <HomeOutlinedIcon
                className="ml-2 "
                style={{ width: '35px', height: '35px' }}
              />
            </RouterLink>
          </div>
          <div className="flex justify-end items-center ">
            <RouterLink
              to="/category-list"
              className="blue text-lg mr-3 mb-5 "
              onClick={ShowSideBar}
            >
              {t('categories')}
              <CategoryIcon
                className="ml-2 "
                style={{ width: '35px', height: '35px' }}
              />
            </RouterLink>
          </div>
          <div className="flex justify-end items-center ">
            <RouterLink
              to="/recommended-list"
              className="blue text-lg mr-3 mb-5 "
              onClick={ShowSideBar}
            >
              {t('recommended-items')}
              <StarsIcon
                className="ml-2 "
                style={{ width: '35px', height: '35px' }}
              />
            </RouterLink>
          </div>
          <div className="flex justify-end items-center ">
            <RouterLink
              to="/shopping-cart"
              className="blue text-lg mr-3 mb-5 "
              onClick={ShowSideBar}
            >
              {t('your-cart')}
              <ShoppingCartOutlinedIcon
                className="ml-2 "
                style={{ width: '35px', height: '35px' }}
              />
            </RouterLink>
          </div>
          <div className="pt-2 flex justify-end items-center ">
            <RouterLink
              to="/follow-order"
              className="blue text-lg mr-3 mb-5"
              onClick={ShowSideBar}
            >
              {t('your-order')}
              <LocalShippingOutlinedIcon
                className="ml-2"
                style={{ width: '35px', height: '35px' }}
              />
            </RouterLink>
          </div>
          <div className="pt-2 flex justify-end items-center  ">
            <RouterLink
              to="/contact-us"
              className="blue text-lg mr-3 mb-5"
              onClick={ShowSideBar}
            >
              {t('contact-us')}
              <MailOutlineIcon
                className="ml-2 "
                style={{ width: '35px', height: '35px' }}
              />
            </RouterLink>
          </div>
          <div className="pt-2 flex justify-end items-center  ">
            <RouterLink
              to="/about-us"
              onClick={ShowSideBar}
              className="blue text-lg mr-3 mb-5"
            >
              {t('about-us')}
              <InfoOutlinedIcon
                className="ml-2"
                style={{ width: '35px', height: '35px' }}
              />
            </RouterLink>
          </div>
          <div className="pt-2 flex justify-end items-center text-lg mb-5">
            <LanguageSidebar />
            <div className="blue text-lg mr-3 ">
              <LanguageIcon
                className="ml-2"
                style={{
                  width: '35px',
                  height: '35px',
                }}
              />
            </div>
          </div>
          <div
            className=" flex justify-end items-center"
            onClick={changeCurrency}
          >
            <div className="blue text-lg mt-2 mb-5">{t('change-currency')}</div>
            {localStorage.getItem('money') === 'dinar' ? (
              <div className="blue text-lg mr-3 ml-2 mt-2 mb-5">
                <div className="dinar text-xl">IQD</div>
              </div>
            ) : (
              <div className="blue text-lg mr-3 mt-2 mb-5">
                <AttachMoneyIcon
                  className="ml-2"
                  style={{ width: '35px', height: '35px' }}
                />
              </div>
            )}
          </div>
        </div>
      </span>
    </div>
  );
};

export default Sidebar;
