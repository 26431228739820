import React, { useState } from 'react';
import './Navbar.css';
import NavbarLogo from './NavbarLogo.PNG';
import { Link as RouterLink } from 'react-router-dom';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Sidebar from './Sidebar';
import { useTranslation } from 'react-i18next';
import LanguageNavbar from './LanguageNavbar';
import SearchProducts from '../../components/product/search/SearchProducts';

const Navbar = () => {
  const [OpenSideBar, SetOpenSideBar] = useState(false);
  const { t, i18n } = useTranslation('common');

  const changeCurrency = () => {
    if (localStorage.getItem('money') === 'dinar') {
      localStorage.setItem('money', 'dollar');
      window.location.reload();
    } else {
      localStorage.setItem('money', 'dinar');
      window.location.reload();
    }
  };

  const ShowSideBar = () => {
    SetOpenSideBar(!OpenSideBar);
  };

  return (
    <div className="height-mob-nav md:h-20 margin-lap bg-new-color">
      <nav
        className={`md:py-3 ${
          OpenSideBar ? 'py-0' : 'py-1'
        } fixed z-40 w-screen bg-new-color`}
      >
        <div className="flex justify-evenly md:justify-between">
          <div className="md:hidden">
            <RouterLink to="/">
              <img
                src={NavbarLogo}
                alt=""
                width="150"
                height="60"
                className="mr-24 ml-1 mt-1"
              />
            </RouterLink>
          </div>
          <div className="md:flex justify-around w-1/5 ml-5 hidden md:block">
            <RouterLink to="/">
              <img
                src={NavbarLogo}
                alt=""
                width="150"
                height="50"
                className="pt-1 mr-3"
              />
            </RouterLink>
          </div>
          <div className="pt-3 w-3/5 hidden md:block">
            <SearchProducts />
            <div className="flex justify-start">
              <div className="pt-3 mr-3 ">
                <RouterLink to={`/category-list`}>{t('categories')}</RouterLink>
              </div>
              <div className="pt-3">
                <RouterLink to={`/recommended-list`}>
                  {t('recommended-items')}
                </RouterLink>
              </div>
            </div>
          </div>
          <span className="md:flex justify-center w-1/5 hidden pt-3 mr-5">
            <RouterLink to="/about-us">
              <abbr title={t('about-us')}>
                <InfoOutlinedIcon
                  className="mx-4 x"
                  style={{
                    width: '30px',
                    height: '30px',
                  }}
                />
              </abbr>
            </RouterLink>
            <RouterLink to="/contact-us">
              <abbr title={t('contact-us')}>
                <MailOutlineIcon
                  style={{
                    width: '30px',
                    height: '30px',
                  }}
                />
              </abbr>
            </RouterLink>
            <RouterLink to="/follow-order">
              <abbr title={t('your-order')}>
                <LocalShippingOutlinedIcon
                  className="mx-4 x"
                  style={{
                    width: '30px',
                    height: '30px',
                  }}
                />
              </abbr>
            </RouterLink>
            <RouterLink to="/shopping-cart">
              <abbr title={t('your-cart')}>
                <ShoppingCartOutlinedIcon
                  style={{
                    width: '30px',
                    height: '30px',
                  }}
                />
              </abbr>
            </RouterLink>
            <LanguageNavbar t={t} i18n={i18n} />

            {localStorage.getItem('money') === 'dinar' ? (
              <div className="mt-1 dinar text-xl" onClick={changeCurrency}>
                <b>IQD</b>
              </div>
            ) : (
              <AttachMoneyIcon
                onClick={changeCurrency}
                style={{
                  width: '30px',
                  height: '30px',
                  cursor: 'pointer',
                }}
              />
            )}
          </span>
          <span className="md:hidden flex justify-end pt-3 px-2">
            <MenuRoundedIcon
              onClick={ShowSideBar}
              className=""
              style={{
                width: '40px',
                height: '40px',
                textAlign: 'right',
              }}
            />
          </span>
          {OpenSideBar ? (
            <Sidebar
              ShowSideBar={ShowSideBar}
              changeCurrency={changeCurrency}
            />
          ) : null}
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
