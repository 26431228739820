import React, { useContext, useEffect, useState } from 'react';
import Cross from '@material-ui/icons/Close';
import { addOrder } from '../../services/client';
import Button3D from '../../common/layout/Button3D';
import { errorAlert3D } from '../../common/error3D';
import { useTranslation } from 'react-i18next';
import './OrderPage.css';
import { CurrencyContext } from '../../services/context/CurrencyContext';
import TotalPrice from './TotalPrice';

const OrderConfirmationModal = ({
  order,
  openConfirmationModal,
  closeConfirmationModal,
  orderForm,
  setSubmitting,
  resetForm,
  setConfirmedCompleteOrderModal,
  setConfirmationMessageCompleteModal,
}) => {
  if (!openConfirmationModal) {
    return null;
  }

  const submitOrder = () =>
    addOrder(orderForm)
      .then((res) => {
        setConfirmedCompleteOrderModal(true);
        setConfirmationMessageCompleteModal(res.data.mobileNumber);
        localStorage.clear();
        resetForm({});
        closeConfirmationModal();
      })
      .catch((err) => {
        errorAlert3D(err);
        setSubmitting(false);
      });

  return (
    <div className="w-full h-full bg-model fixed top-0 left-0 overflow-y-auto  flex justify-center items-center z-40">
      <div
        className="md:w-2/5 md:h-75 model-res fixed  text-center md:rounded-lg p-4 md:relative overflow-y-auto"
        style={{ backgroundColor: 'var(--color-secondary)' }}
      >
        <div className="flex justify-end model-p text-xl">
          <Cross
            onClick={closeConfirmationModal}
            className="cursor-pointer"
            width="20"
            height="20"
            alt="exit"
          />
        </div>

        <ConfirmationModalContent
          order={order}
          orderForm={orderForm}
          close={closeConfirmationModal}
          submitOrder={submitOrder}
        />
      </div>
    </div>
  );
};

export default OrderConfirmationModal;

const ConfirmationModalContent = ({ order, orderForm, close, submitOrder }) => {
  const { t } = useTranslation('order_page');
  const [totalPrice, setTotalPrice] = useState(0);
  const currency = useContext(CurrencyContext);

  useEffect(() => {
    if (localStorage.getItem('money') === 'dollar') {
      setTotalPrice(order.totalPrice);
    } else {
      setTotalPrice(order.totalPrice * currency.currencyPrice);
    }
  }, [currency, order]);

  return (
    <div className="md:py-0">
      <div className="model-p text-lg py-2">
        <div>{t('about-to-buy')}</div>
        <div className="p-1">{t('total')}</div>
        <div className="p-1">
          <TotalPrice city={orderForm.city} totalPrice={totalPrice} />
        </div>
        <div className="p-1">
          {order.products.map((productItem, i) => (
            <OrderItem key={i} productItem={productItem} />
          ))}
        </div>
        <div className="flex justify-center">
          <hr width="80" className="border-2 border-conf" />
        </div>
        <div className="pt-3">{t('user-info')}:</div>
        <div className="flex justify-center p-1">
          <div>{orderForm.name}</div>
        </div>
        <div className="p-1">
          {orderForm.district} , {orderForm.city}
        </div>
        <div className="p-1">{orderForm.mobileNumber}</div>
      </div>
      <div className="flex justify-between p-1">
        <Button3D
          type="submit"
          variant="confirm-purchase"
          onClick={submitOrder}
          text={t('confirm')}
        />
        <div className="ml-3">
          <Button3D
            variant="confirm-purchase"
            onClick={close}
            text={t('back')}
          />
        </div>
      </div>
    </div>
  );
};

const OrderItem = ({ productItem }) => {
  const [price, setPrice] = useState(0);
  const currency = useContext(CurrencyContext);

  useEffect(() => {
    if (localStorage.getItem('money') === 'dollar') {
      setPrice(productItem.price);
    } else {
      setPrice(productItem.price * currency.currencyPrice);
    }
  }, [currency, productItem]);

  return (
    <div className="flex justify-center">
      <div className="mx-2">{productItem.name} </div>
      <div className="flex justify-center">
        {price.toLocaleString()}
        <div className="ml-1 mr-2">
          {localStorage.getItem('money') === 'dollar' ? '$' : 'IQD'}
        </div>
      </div>
    </div>
  );
};
