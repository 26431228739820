import React from 'react';

const Ribbon3D = ({ text, variant }) => {
  const styleRibbon = {
    position: 'absolute',
    top: '30px',
    left: '-2rem',
    width: '10rem',
    transform: 'rotate(-45deg)',
  };

  switch (variant) {
    case 'red':
      return <RedRibbon style={styleRibbon} text={text} />;
    case 'light-red':
      return <LightRedRibbon style={styleRibbon} text={text} />;
    case 'very-red':
      return <VeryRedRibbon style={styleRibbon} text={text} />;
    case 'yellow-lrt':
      return <YellowRibbon style={styleRibbon} text={text} />;
    default:
      return null;
  }
};

export default Ribbon3D;

const LightRedRibbon = ({ style, text }) => {
  return (
    <div>
      <span
        style={style}
        className="ribbon bg-red-500 text-sm text-center whitespace-no-wrap"
      >
        {text}
      </span>
    </div>
  );
};

const RedRibbon = ({ style, text }) => {
  return (
    <div>
      <span
        style={style}
        className="ribbon bg-red-700 text-sm text-center whitespace-no-wrap"
      >
        {text}
      </span>
    </div>
  );
};

const VeryRedRibbon = ({ style, text }) => {
  return (
    <div>
      <div
        style={style}
        className="ribbon bg-red-900 text-sm text-center whitespace-no-wrap"
      >
        {text}
      </div>
    </div>
  );
};

const YellowRibbon = ({ style, text }) => {
  return (
    <div>
      <div
        style={style}
        className="ribbon bg-yellow-600 text-sm text-center whitespace-no-wrap"
      >
        {text}
      </div>
    </div>
  );
};
