import React, { useContext, useState } from 'react';
import '../../../../common/dropdown/DropDown3D.css';

import Spinner3D from '../../../../common/spinner/Spinner3D';
import { CategoryContext } from '../../../../services/context/CategoryContext';
import DropDown3D from '../../../../common/dropdown/DropDown3D';

export const CategoryDropDownList = (props) => {
  const { categoryList, loading } = useContext(CategoryContext);

  const catList = categoryList.map((cat) => {
    let category = {};
    category.id = cat.id;
    category.name = cat.categoryName;
    return category;
  });

  const [selectedCategoryName, setSelectedCategoryName] = useState(
    props.currentCategoryName
  );

  const handleChange = (e) => {
    setSelectedCategoryName(e.target.name);
    if (props.form) {
      props.form.setFieldValue(props.field.name, e.target.id);
    } else {
      props.setCategoryId(e.target.id);
    }
  };

  if (loading) {
    return <Spinner3D />;
  }

  return (
    <div>
      <DropDown3D
        list={catList}
        selectedName={selectedCategoryName}
        handleChange={handleChange}
      />
    </div>
  );
};
