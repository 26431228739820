import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { getProductsByCategory } from '../../../services/client';
import ProductListView from '../productsList/ProductListView';
import Spinner3D from '../../../common/spinner/Spinner3D';

function ProductCategoryLists() {
  const [loading, isLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [products, setProducts] = useState([]);
  let { categoryId } = useParams();

  let { state } = useLocation();

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  const prevState = usePrevious(state);

  useEffect(() => {
    isLoading(true);
    if (typeof state !== 'undefined' && state !== prevState) {
      getProductsByCategory(categoryId, state.currentPage, 'createdAt').then(
        (res) => {
          setTotalElements(res.data.totalElements);
          setProducts(res.data.content);
          isLoading(false);
          setCurrentPage(res.data.number);
          setItemsCountPerPage(res.data.size);
          setPageNumber(state.currentPage);
        }
      );
    } else {
      getProductsByCategory(categoryId, pageNumber, 'createdAt').then((res) => {
        setTotalElements(res.data.totalElements);
        setProducts(res.data.content);
        isLoading(false);
        setCurrentPage(res.data.number);
        setItemsCountPerPage(res.data.size);
      });
    }
  }, [state, categoryId, pageNumber]);

  if (loading) {
    return <Spinner3D />;
  }

  return (
    <div className="md:flex xl:px-16">
      <ProductListView
        totalElements={totalElements}
        setPageNumber={setPageNumber}
        currentPage={currentPage}
        itemsCountPerPage={itemsCountPerPage}
        products={products}
      />
    </div>
  );
}

export default ProductCategoryLists;
