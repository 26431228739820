import i18next from 'i18next';

import common_ar from './translations/ar/common.json';
import order_page_ar from './translations/ar/order_page.json';
import product_cards_ar from './translations/ar/product_cards.json';
import footer_ar from './translations/ar/footer.json';
import about_us_ar from './translations/ar/about-us.json';
import contact_us_ar from './translations/ar/contact-us.json';

import common_en from './translations/en/common.json';
import order_page_en from './translations/en/order_page.json';
import product_cards_en from './translations/en/product_cards.json';
import footer_en from './translations/en/footer.json';
import about_us_en from './translations/en/about-us.json';
import contact_us_en from './translations/en/contact-us.json';

const languageCheck = () => {
  if ('lng' in localStorage) {
    return localStorage.getItem('lng');
  } else {
    return 'ar';
  }
};

i18next.init({
  fallbackLng: 'ar',
  interpolation: { escapeValue: false },
  lng: languageCheck(),
  resources: {
    ar: {
      common: common_ar,
      order_page: order_page_ar,
      product_cards: product_cards_ar,
      footer: footer_ar,
      about_us: about_us_ar,
      contact_us: contact_us_ar,
    },
    en: {
      common: common_en,
      order_page: order_page_en,
      product_cards: product_cards_en,
      footer: footer_en,
      about_us: about_us_en,
      contact_us: contact_us_en,
    },
  },
});

export default i18next;
