import React from 'react';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import AddCommentIcon from '@material-ui/icons/AddComment';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

const Button3D = ({ text, onClick, variant, type, disabled }) => {
  switch (variant) {
    case 'logout':
      return <LogoutButton text={text} onClick={onClick} />;
    case 'edit-table':
      return <EditButton3D onClick={onClick} />;
    case 'submit-button':
      return (
        <SubmitButton
          disabled={disabled}
          type={type}
          text={text}
          onClick={onClick}
        />
      );
    case 'admin-main':
      return <MainButton3D text={text} onClick={onClick} type={type} />;
    case 'delete-table':
      return <DeleteButton3D onClick={onClick} />;
    case 'shopping-cart':
      return <ShoppingCartButton3D text={text} onClick={onClick} />;
    case 'add-shopping-cart':
      return <AddShoppingCartButton3D text={text} onClick={onClick} />;
    case 'shopping-cart-items-delete':
      return <ShoppingCartItemsButton text={text} onClick={onClick} />;
    case 'search':
      return <SearchButton text={text} type={type} onClick={onClick} />;
    case 'order-status':
      return <OrderStateButton text={text} onClick={onClick} />;
    case 'confirm-purchase':
      return <ConfirmPurchaseButton text={text} onClick={onClick} />;
    case 'add-info-table':
      return <AddInfoButton3D onClick={onClick} />;
    default:
      return <DefaultButton text={text} onClick={onClick} />;
  }
};

export default Button3D;

const DefaultButton = ({ text, onClick, type }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className="border-2 rounded-md b-border w-32 h-12  hover:bg-blue-900"
    >
      {text}
    </button>
  );
};

const LogoutButton = ({ text, onClick }) => {
  return (
    <button
      className="border-2 rounded-md p-1 bg-red-400 text-white w-32 mx-2 hover:bg-red-900"
      onClick={onClick}
    >
      {text}
    </button>
  );
};

const EditButton3D = ({ onClick }) => {
  return (
    <button
      className="hover:bg-blue-500 bg-blue text-white font-semibold hover:text-blue-900 py-1 px-4 border blue-500 hover:border-transparent rounded"
      onClick={onClick}
    >
      <Edit />
    </button>
  );
};

const DeleteButton3D = ({ onClick }) => {
  return (
    <button
      className="hover:bg-red-900 bg-red-700 text-white font-semibold hover:text-blue-700 py-1 px-4 border blue-500 hover:border-transparent rounded"
      onClick={onClick}
    >
      <Delete />
    </button>
  );
};

const AddInfoButton3D = ({ onClick }) => {
  return (
    <button
      className="hover:bg-blue-500 bg-blue text-white font-semibold hover:text-blue-900 py-1 px-4 border blue-500 hover:border-transparent rounded"
      onClick={onClick}
    >
      <AddCommentIcon />
    </button>
  );
};

const MainButton3D = ({ text, onClick, type }) => {
  return (
    <button
      className="border-2 rounded-md p-1 bg-blue text-white w-32 mx-2 hover:bg-blue-900"
      onClick={onClick}
      type={type}
    >
      {text}
    </button>
  );
};

const ShoppingCartButton3D = ({ text, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="border-2 text-sm rounded-md b-border w-40 h-10 hover:bg-blue-900"
    >
      <div className="flex justify-center">
        <ShoppingCartIcon className="mx-1" />
        {text}
      </div>
    </button>
  );
};

const AddShoppingCartButton3D = ({ text, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="border-2 text-sm rounded-md b-border w-40 h-10 hover:bg-blue-900"
    >
      <div className="flex justify-center">
        <ShoppingCartIcon className="mx-1" />
        {text}
      </div>
    </button>
  );
};

const ShoppingCartItemsButton = ({ text, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="border-2 rounded-md b-border px-2 h-12 mx-1 hover:bg-blue-900"
    >
      <div className="flex justify-around">{text}</div>
    </button>
  );
};

const OrderStateButton = ({ text, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="border-2 rounded-md b-border w-32 text-sm h-10  hover:bg-blue-900"
    >
      <div className="flex justify-around">{text}</div>
    </button>
  );
};

const SearchButton = ({ text, onClick, type }) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className="border-2 rounded-md b-border w-20 text-sm h-10  hover:bg-blue-900"
    >
      <div className="flex justify-around">{text}</div>
    </button>
  );
};

const ConfirmPurchaseButton = ({ text, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="border-2 rounded-md b-border w-40 text-sm h-10  hover:bg-blue-900"
    >
      <div className="flex justify-around">{text}</div>
    </button>
  );
};

const SubmitButton = ({ text, onClick, disabled, type }) => {
  return (
    <button
      onClick={onClick}
      className="bg-blue border text-white rounded-lg p-2 w-40 text-sm hover:bg-blue-900"
      disabled={disabled}
      type={type}
    >
      <div className="flex justify-around">{text}</div>
    </button>
  );
};
