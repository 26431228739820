import React from 'react';
import { Field } from 'formik';
import Badge3D from '../layout/Badge3D';

export const FormikContactUsForm = (props) => {
  return (
    <div>
      <label className="label-contact-us">{props.label}</label>
      <Field
        style={{
          direction: 'rtl',
          height: `${props.height}`,
          borderRadius: '0.37rem',
        }}
        className="form-control my-form shadow-sm outline-none input-w "
        name={props.name}
        type={props.type}
        as={props.as}
      />
      <div className="pt-2 flex justify-left">
        {props.errors && props.touched && (
          <Badge3D variant="warning" text={props.errors} />
        )}
      </div>
    </div>
  );
};
