import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { I18nextProvider } from 'react-i18next';
import i18next from './i18n';
import SimpleReactLightbox from 'simple-react-lightbox';

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <SimpleReactLightbox>
      <App />
    </SimpleReactLightbox>
  </I18nextProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
