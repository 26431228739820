import React from 'react';
import Cross from '@material-ui/icons/Close';
import { Link as RouterLink } from 'react-router-dom';
import Button3D from '../../../common/layout/Button3D';
import { useTranslation } from 'react-i18next';

const AddProductToCartModal = ({ open, close }) => {
  const { t } = useTranslation('common');
  if (!open) return null;
  return (
    <div className="w-full h-full bg-model fixed top-0 left-0  flex justify-center items-center z-40">
      <div
        className="md:w-2/5 md:h-75 model-res  text-center md:rounded-lg p-4 md:relative "
        style={{ backgroundColor: 'var(--color-secondary)' }}
      >
        <div className="flex justify-end model-p text-xl">
          <Cross
            className="cursor-pointer"
            onClick={close}
            width="20"
            height="20"
            alt="exit"
          />
        </div>

        <div className="">
          <div className="flex justify-center">
            <p className="text-lg py-2 blue">{t('added')}</p>
          </div>

          <div className="flex justify-evenly p-4">
            <RouterLink to="/shopping-cart" className="mr-3">
              <Button3D type="submit" onClick={close} text={t('go-to-cart')} />
            </RouterLink>
            <RouterLink to="/">
              <Button3D onClick={close} text={t('home')} />
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProductToCartModal;
