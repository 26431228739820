import React from 'react';
import Cross from '@material-ui/icons/Close';
import Moment from 'moment';

const MoreOrderInfo = ({ moreOrderInfoModel, setMoreOrderInfoModel }) => {
  const productList = moreOrderInfoModel.order.orderProductsResponse.map(
    (product, i) => (
      <table key={i} className="mt-2 border-bottom">
        <tbody>
          <tr>
            <td className="px-4">Name</td>
            <td className="px-3">{product.productName}</td>
          </tr>
          <tr>
            <td className="px-4">Product amount</td>
            <td className="px-3">{product.productCount}</td>
          </tr>
          <tr>
            <td className="px-4">Product price</td>
            <td className="px-3">{product.productPrice}</td>
          </tr>
          <tr>
            <td className="px-4">Total amount</td>
            <td className="px-3">{product.totalAmount}</td>
          </tr>
        </tbody>
      </table>
    )
  );

  return (
    <div className=" items-center z-40">
      <div
        className="  md:rounded-lg p-4 md:relative text-lg my-5"
        style={{ backgroundColor: 'var(--color-secondary)' }}
      >
        <div className="flex justify-end model-p text-xl">
          <Cross
            className="cursor-pointer"
            onClick={() => setMoreOrderInfoModel(!moreOrderInfoModel.showModel)}
            width="20"
            height="20"
            alt="exit"
          />
        </div>
        <div className="flex justify-center model-p text-xl m-3">
          <table className="table-fixed">
            <tbody>
              <tr>
                <td className="border px-4 py-2">Order Track Id</td>
                <td className="border px-3 py-2">
                  {moreOrderInfoModel.order.orderTrackId}
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">Name</td>
                <td className="border px-3 py-2">
                  {moreOrderInfoModel.order.name}
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">Company name</td>
                <td className="border px-3 py-2">
                  {moreOrderInfoModel.order.companyName}
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">District</td>
                <td className="border px-3 py-2">
                  {moreOrderInfoModel.order.district}
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">District extra</td>
                <td className="border px-3 py-2">
                  {moreOrderInfoModel.order.district2}
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">Mobile Number</td>
                <td className="border px-3 py-2">
                  {moreOrderInfoModel.order.mobileNumber}
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">E-mail</td>
                <td className="border px-3 py-2">
                  {moreOrderInfoModel.order.email}
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">City</td>
                <td className="border px-3 py-2">
                  {moreOrderInfoModel.order.city}
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">Total Amount</td>
                <td className="border px-3 py-2">
                  {moreOrderInfoModel.order.totalAmount}
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">localDate</td>
                <td className="border px-3 py-2">
                  {Moment(moreOrderInfoModel.order.localDate).format(
                    'H:mm dddd D/M/Y'
                  )}
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">Notes</td>
                <td className="border px-3 py-2">
                  {moreOrderInfoModel.order.notes}
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">Products Amount</td>
                <td className="border px-3 py-2">
                  {moreOrderInfoModel.order.itemAmount}
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">Order State</td>
                <td className="border px-3 py-2">
                  {moreOrderInfoModel.order.orderState}
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">ِExtra info Order</td>
                <td className="border px-3 py-2">
                  {moreOrderInfoModel.order.extraInfoOrder}
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">Products</td>
                <td className="border px-3 py-2">{productList}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default MoreOrderInfo;
