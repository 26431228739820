import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Lang.css';

const LanguageNavbar = () => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const { t, i18n } = useTranslation('common');

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('lng', lng);
  };
  const iDropdownOpen = (value) => {
    setOpenDropdown(value);
  };

  return (
    <div>
      <div onClick={() => iDropdownOpen(true)}>
        <div className="inline md:hidden">{t('language')}</div>
        {openDropdown ? (
          <div className="absolute w-32 md:m-0 -ml-16 ipxs rounded-lg bg-black">
            <button
              onMouseLeave={() =>
                setTimeout(() => {
                  setOpenDropdown(false);
                }, 2000)
              }
              onClick={() => changeLanguage('ar')}
              className="block  w-32 px-4 py-2 text-gray-800 hover:bg-gray-900"
              style={{ color: 'var(--color-text)' }}
            >
              عربي
            </button>
            <button
              onMouseLeave={() =>
                setTimeout(() => {
                  setOpenDropdown(false);
                }, 2000)
              }
              onClick={() => changeLanguage('en')}
              className="block  w-32 px-4 py-2 text-gray-800 hover:bg-gray-900"
              style={{ color: 'var(--color-text)' }}
            >
              English
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default LanguageNavbar;
