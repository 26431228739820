import React from 'react';
import './DropDown3D.css';

const DropDown3D = ({ list, selectedName, handleChange }) => {
  const decideColorForOrderState = (orderState) => {
    switch (orderState) {
      case 'RECEIVED':
        return 'border-2 b-border hover:bg-blue-900';
      case 'IN_PROGRESS':
        return 'bg-yellow-900';
      case 'SHIPPED':
        return 'bg-yellow-900';
      case 'DELIVERED':
        return 'bg-green-800';
      case 'COMPLETED':
        return 'bg-green-800';
      case 'CANCELLED':
        return 'bg-red-800';
      default:
        return 'border-2 b-border';
    }
  };

  return (
    <div className="dropdown">
      <button
        className={`text-center text-white rounded-md w-40 h-10 hover:bg-blue-900 hover:text-white ${decideColorForOrderState(
          selectedName
        )}`}
      >
        {selectedName}
      </button>

      <div
        className="dropdown-menu absolute hidden pt-1"
        style={{
          backgroundColor: 'var(--color-background)',
          float: 'left',
          border: '2px solid',
          borderColor: 'var(--color-text)',
          height: '275px',
          overflowY: 'scroll',
          width: '500px',
        }}
      >
        {list.map((listItem, i) => (
          <div key={i}>
            <button
              onClick={(e) => handleChange(e)}
              className="rounded-t hover:bg-blue-900 py-2 px-4 w-40 whitespace-no-wrap"
              key={listItem.id}
              id={listItem.id}
              name={listItem.name}
            >
              {listItem.name}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DropDown3D;
