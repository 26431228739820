import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import Button3D from '../../../../common/layout/Button3D';

const GoBackButton = ({
  currentPage,
  history,
  product,
  searchBack,
  searchedProductName,
}) => {
  const { t } = useTranslation('common');

  if (searchBack !== null && typeof searchBack !== 'undefined') {
    return (
      <RouterLink
        to={{
          pathname: `/search-products/${searchedProductName}`,
          state: { currentPage },
        }}
      >
        <Button3D text={t('go-back-shopping')} />
      </RouterLink>
    );
  }

  if (currentPage >= 0) {
    return (
      <RouterLink
        to={{
          pathname: `/category/${product.category.id}`,
          state: { currentPage },
        }}
      >
        <Button3D text={t('go-back-shopping')} />
      </RouterLink>
    );
  }

  return (
    <Button3D onClick={() => history.goBack()} text={t('go-back-shopping')} />
  );
};

export default GoBackButton;
