import React from 'react';
import { SRLWrapper } from 'simple-react-lightbox';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ProductImages = ({ product }) => {
  const settings = {
    arrows: false,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
    <SRLWrapper>
      <Slider {...settings}>
        <img src={product.picLocation} alt="ecommerce" />
        {product.imageJsonResponses.map((img, i) => (
          <img key={i} src={img.productImageLocation} alt="ecommerce" />
        ))}
      </Slider>
    </SRLWrapper>
  );
};

export default ProductImages;
