import React from 'react';
import Cross from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

const OrderSubmitModal = ({
  textColor,
  message,
  setConfirmedCompleteOrderModal,
  openConfirmedCompleteOrder,
}) => {
  const { t } = useTranslation('order_page');

  if (!openConfirmedCompleteOrder) {
    return null;
  }

  const close = () => {
    setConfirmedCompleteOrderModal(false);
  };

  return (
    <div className="w-full h-full bg-model fixed top-0 left-0  flex justify-center items-center z-40">
      <div
        className="md:w-2/5 md:h-75 model-res  text-center md:rounded-lg p-4 md:relative"
        style={{ backgroundColor: 'var(--color-secondary)' }}
      >
        <div className="flex justify-end model-p text-xl">
          <Cross
            className="cursor-pointer"
            onClick={close}
            width="20"
            height="20"
            alt="exit"
          />
        </div>

        <div className="center-confirm-modal p1">
          <div
            color={textColor}
            className="flex justify-center model-p text-lg py-2"
          >
            <div>
              <h2>{message}</h2>
              <h5 className="blue p-1">{t('received')}،</h5>
              <h5 className="blue p-1">{t('follow up')}</h5>
              <h5 className="my-2">
                <a className="blue no-underline mt-4" href="/follow-order ">
                  <div className="text-white">{t('press')}</div>
                </a>
              </h5>
              <h6 className="mt-2">{t('sent')}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSubmitModal;
