import React from 'react';
import './NotFoundPage.css';

class NotFoundPage extends React.Component {
  render() {
    return (
      <div
        className="h-screen w-screen flex justify-center content-center flex-wrap"
        style={{ backgroundColor: 'var(--color-background)' }}
      >
        <p className="font-sans error-text">404</p>
      </div>
    );
  }
}

export default NotFoundPage;
