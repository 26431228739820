import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Button3D from '../layout/Button3D';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

export const UploadImage = (props) => {
  const [images, setImages] = useState([]);
  const onDrop = useCallback(
    (acceptedFiles) => {
      // set Images to be shown when selected
      setImages(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      // set all the image objects to Fomik
      props.setFieldValue(props.name, acceptedFiles);
    },
    [props]
  );

  // for images to be shown when selected
  const thumbs = images.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} alt="" />
      </div>
    </div>
  ));

  // for images to be shown when selected
  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      images.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [images]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    onDrop,
    maxFiles: props.maxFiles,
    accept: 'image/jpeg, image/png',
    minSize: 0,
    maxSize: 1048576,
  });

  // for images name and size to be shown when selected
  const imagesNameAndSize = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  // when rejected for any reason, show this
  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code} style={{ color: 'red' }}>
            {e.message === 'Too many files'
              ? 'You can upload only ' + props.maxFiles + ' image/s'
              : e.message}
          </li>
        ))}
      </ul>
    </li>
  ));

  // **** if the user wants to upload more than one file, he should choose
  // at the same time!
  return (
    <div
      {...getRootProps({
        onClick: (event) => event.preventDefault(),
      })}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <Button3D text="Drop the image here..." variant="submit-button" />
      ) : (
        <Button3D text={props.buttonText} variant="submit-button" />
      )}
      <aside style={thumbsContainer}>{thumbs}</aside>
      <aside>
        <ul className="pt-2">{imagesNameAndSize}</ul>
        <ul>{fileRejectionItems}</ul>
      </aside>
    </div>
  );
};
