import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Spinner3D from '../../../common/spinner/Spinner3D';
import { getRecommendedProducts } from '../../../services/client';

const RecommendedProductsPageList = () => {
  const { t } = useTranslation('common');
  const [loading, isLoading] = useState(false);
  const [recommendedProducts, setRecommendedProducts] = useState([]);

  useEffect(() => {
    isLoading(true);
    getRecommendedProducts().then((res) => {
      setRecommendedProducts(res.data);
      isLoading(false);
    });
  }, []);

  if (loading) {
    return <Spinner3D />;
  }

  return (
    <section className="md:pt-16">
      <div className="container px-5 py-24 mx-auto">
        <p
          className="titleCategory blue font-bold flex justify-center pt-4"
          style={{ fontSize: '130%' }}
        >
          <div className="md:text-5xl text-2xl"> {t('recommended-items')}</div>
        </p>
        <div className="pt-5 flex flex-wrap">
          {recommendedProducts.map((product, i) => (
            <div key={i} className="lg:w-1/6 sm:w-1/2 p-2 w-full">
              <RouterLink
                to={`/product/${product.id}`}
                className="block relative h-40 rounded overflow-hidden"
              >
                <img
                  alt="ecommerce"
                  className="object-cover object-center w-full h-full block"
                  src={product.picLocation}
                />
              </RouterLink>
              <div className="mt-2">
                <h2 className="text-xl flex justify-center">{product.name}</h2>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default RecommendedProductsPageList;
