import React from 'react';
import './LoginPage.css';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { ACCESS_TOKEN, USER } from '../../../services/constants';
import { login } from '../../../services/client';
import Badge3D from '../../../common/layout/Badge3D';
import Button3D from '../../../common/layout/Button3D';

const validationSchema = yup.object({
  userName: yup.string().required('User name is required'),
  password: yup.string().required('Password is required'),
});

const LoginPage = () => {
  return (
    <div>
      <div className="py-16">
        <div className="mx-auto">
          <div
            className="card card-signin my-5 "
            style={{ background: 'var(--color-secondary)' }}
          >
            <div className="card-body">
              <h3 style={{ color: 'red' }} className="text-center text-3xl">
                لا يشترط تسجيل الدخول حاليا، بأمكانك طلب البضاعة مباشرة بدون
                تسجيل الدخول
              </h3>
              <h5 className="card-title text-center pt-10">Sign In</h5>
              <Formik
                initialValues={{
                  userName: '',
                  password: '',
                  // rememberMe: false
                }}
                validationSchema={validationSchema}
                onSubmit={(loginInfo, { setSubmitting }) => {
                  login(loginInfo)
                    .then((response) => {
                      localStorage.setItem(
                        ACCESS_TOKEN,
                        response.headers.authorization
                      );
                      localStorage.setItem(USER, response.headers.user);
                      setSubmitting(false);
                      window.location = '/admin';
                    })
                    .catch((err) => {
                      setSubmitting(false);
                    });
                }}
              >
                {({ errors, touched, isSubmitting }) => (
                  <Form>
                    <div className="grid grid-rows-none justify-items-center gap-5">
                      <div>
                        <label className="mr-4">User Name</label>
                        <Field
                          className="mr-3 px-3 border rounded h-10 w-full"
                          style={{
                            backgroundColor: 'var(--color-background)',
                            borderRadius: 150,
                          }}
                          name="userName"
                          type="text"
                          as="input"
                        />
                        {errors.userName && touched.userName && (
                          <Badge3D variant="warning" text={errors.userName} />
                        )}
                      </div>
                      <div>
                        <label className="mr-4">Password</label>
                        <Field
                          className="mr-3 px-3 border rounded h-10 w-full"
                          style={{
                            backgroundColor: 'var(--color-background)',
                            borderRadius: 150,
                          }}
                          name="password"
                          type="password"
                          as="input"
                        />
                        {errors.password && touched.password && (
                          <Badge3D variant="warning" text={errors.password} />
                        )}
                      </div>
                      <Button3D
                        variant="submit-button"
                        disabled={isSubmitting}
                        type="submit"
                        text="Sign in"
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
