import React, { useState } from 'react';
import CategoryTableAdmin from './category/CategoryTableAdmin';
import ProductsTableAdmin from './product/ProductsTableAdmin';
import RecommendedTableAdmin from './product/RecommendedTableAdmin';
import OrderTable from './order/OrderTableAdmin';
import Admin from '../../assets/img/admin.jpg';
import Button3D from '../../common/layout/Button3D';
import CurrencyTable from './currency/CurrencyTable';

const AdminPage = () => {
  const [openProducts, setOpenProducts] = useState(false);
  const [openCategories, setOpenCategories] = useState(false);
  const [openOrders, setOpenOrders] = useState(false);
  const [openRecommended, setOpenRecommended] = useState(false);
  const [renderCurrency, setRenderCurrency] = useState(false);
  const [renderPhoto, setRenderPhoto] = useState(true);

  const categoriesToggle = () => {
    if (openCategories) {
      return;
    }
    setOpenCategories(!openCategories);
    setOpenProducts(false);
    setOpenRecommended(false);
    setOpenOrders(false);
    setRenderPhoto(false);
    setRenderCurrency(false);
  };

  const productsToggle = () => {
    if (openProducts) {
      return;
    }
    setOpenProducts(!openProducts);
    setOpenCategories(false);
    setOpenRecommended(false);
    setOpenOrders(false);
    setRenderPhoto(false);
    setRenderCurrency(false);
  };

  const orderToggle = () => {
    if (openOrders) {
      return;
    }
    setOpenOrders(!openOrders);
    setOpenRecommended(false);
    setOpenProducts(false);
    setOpenCategories(false);
    setRenderPhoto(false);
    setRenderCurrency(false);
  };

  const recommendedToggle = () => {
    if (openRecommended) {
      return;
    }
    setOpenRecommended(!openRecommended);
    setOpenProducts(false);
    setOpenCategories(false);
    setOpenOrders(false);
    setRenderPhoto(false);
    setRenderCurrency(false);
  };

  const currencyExchangeToggle = () => {
    if (renderCurrency) {
      return;
    }
    setRenderCurrency(!renderCurrency);
    setOpenOrders(false);
    setOpenRecommended(false);
    setOpenProducts(false);
    setOpenCategories(false);
    setRenderPhoto(false);
  };

  const logout = () => {
    localStorage.clear();
    window.location = '/';
  };

  return (
    <div className="pt-5">
      {renderPhoto && (
        <div className="text-center mt-5 blue text-5xl">الرئيسية</div>
      )}
      {openProducts && (
        <div className="text-center mt-5 blue text-5xl">المواد</div>
      )}
      {openCategories && (
        <div className="text-center mt-5 blue text-5xl">الأصناف</div>
      )}
      {openOrders && (
        <div className="text-center mt-5 blue text-5xl">الطلبات</div>
      )}
      {renderCurrency && (
        <div className="text-center mt-5 blue text-5xl">تحويل العملة</div>
      )}
      {openRecommended && (
        <div className="text-center mt-5 blue text-5xl">الأكثر مبيعاً</div>
      )}
      <div className="flex justify-center items-center md:items-start mt-3">
        <Button3D
          variant="admin-main"
          text="Products"
          onClick={productsToggle}
        />
        <Button3D
          variant="admin-main"
          text="Categories"
          onClick={categoriesToggle}
        />
        <Button3D variant="admin-main" text="Orders" onClick={orderToggle} />
        <Button3D
          variant="admin-main"
          text="Recommended"
          onClick={recommendedToggle}
        />
        <Button3D
          variant="admin-main"
          text="Currency"
          onClick={currencyExchangeToggle}
        />
        <Button3D variant="logout" text="Log out" onClick={logout} />
      </div>
      <div className="flex justify-center pt-5">
        {renderPhoto ? (
          <div style={{ height: '50%', width: '50%' }}>
            <img src={Admin} alt="admin" />
          </div>
        ) : null}
        {openCategories ? <CategoryTableAdmin /> : null}
        {openProducts ? <ProductsTableAdmin /> : null}
        {openRecommended ? (
          <RecommendedTableAdmin productsToggler={productsToggle} />
        ) : null}
        {openOrders ? <OrderTable /> : null}
        {renderCurrency ? <CurrencyTable /> : null}
      </div>
    </div>
  );
};

export default AdminPage;
