import React from 'react';
import Cross from '@material-ui/icons/Close';
import ReactHtmlParser from 'react-html-parser';

const MoreProductInfo = ({ moreProductInfoModel, setMoreProductInfoModel }) => {
  return (
    <div className="w-full h-full bg-model fixed top-0 left-0  flex justify-center items-center z-40">
      <div
        className="md:w-4/5 md:h-75 model-res  text-center md:rounded-lg p-4 md:relative"
        style={{ backgroundColor: 'var(--color-secondary)' }}
      >
        <div className="flex justify-end model-p text-xl">
          <Cross
            className="cursor-pointer"
            onClick={() =>
              setMoreProductInfoModel(!moreProductInfoModel.showModel)
            }
            width="20"
            height="20"
            alt="exit"
          />
        </div>
        <div className="flex justify-center model-p text-xl m-3">
          <table className="table-fixed">
            <tbody>
              <tr>
                <td className="border px-4 py-2">Description</td>
                <td className="border px-4 py-2">
                  {moreProductInfoModel.product.description &&
                    ReactHtmlParser(moreProductInfoModel.product.description)}
                </td>
              </tr>
              {moreProductInfoModel.product.priceAssemble > 0 ? (
                <tr>
                  <td className="border px-4 py-2">Price Assemble</td>
                  <td className="border px-4 py-2">
                    {moreProductInfoModel.product.priceAssemble}
                  </td>
                </tr>
              ) : null}
              <tr>
                <td className="border px-4 py-2">Image</td>
                <td className="border px-4 py-2">
                  {moreProductInfoModel.product.imageJsonResponses.map(
                    (img, i) => (
                      <td key={i}>
                        <img src={img.productImageLocation} alt="" />
                      </td>
                    )
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default MoreProductInfo;
