import React, { useContext } from 'react';
import { CategoryContext } from '../../services/context/CategoryContext';
import Spinner3D from '../../common/spinner/Spinner3D';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CategoryPageList = () => {
  const { categoryList, loading } = useContext(CategoryContext);
  const { t } = useTranslation('common');

  if (loading) {
    return <Spinner3D />;
  }

  return (
    <section className="md:pt-16">
      <div className="container px-5 py-24 mx-auto">
        <p
          className="titleCategory blue font-bold flex justify-center pt-4"
          style={{ fontSize: '130%' }}
        >
          <div className="md:text-5xl text-2xl"> {t('categories')}</div>
        </p>
        <div className="pt-5 flex flex-wrap">
          {categoryList.map((category, i) => (
            <div key={i} className="lg:w-1/6 sm:w-1/2 p-2 w-full">
              <RouterLink
                to={`/category/${category.id}`}
                className="block relative h-40 rounded overflow-hidden"
              >
                <img
                  alt="ecommerce"
                  className="object-cover object-center w-full h-full block"
                  src={category.categoryImageLocation}
                />
              </RouterLink>
              <div className="mt-2">
                <h2 className="text-xl flex justify-center">
                  {category.categoryName}
                </h2>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CategoryPageList;
