import React from 'react';
import '../about-us/AboutUs.css';
import ReactPlayer from 'react-player';
import MSI from '../../assets/msi-pic.png';
import ASUS from '../../assets/asus.jpg';
import COOLERMASTER from '../../assets/cool-master.png';
import KLEVV from '../../assets/klevv.png';
import LEXAR from '../../assets/lexar.png';
import DEEPCOOL from '../../assets/DEEPCOOL.png';
import LIANLI from '../../assets/LIANLI.jpg';
import FIIO from '../../assets/FIIO.png';
import STEELSERIES from '../../assets/Steelseries.jpeg';
import BAYERDYNAMIC from '../../assets/BAYERDYNAMIC.jpg';
import FIFINE from '../../assets/FIFINE.jpg';
import LOGITECH from '../../assets/Logitech.png';
import HyperX from '../../assets/HyperX.jpg';
import RAZER from '../../assets/razer.jpg';
import PHILLPS from '../../assets/Philips-Shield.webp';
import MEETION from '../../assets/MEETION.jpeg';
import VEDIO from '../../assets/vedio-3d.mp4';
import CREATIVE from '../../assets/creative.png';
import ADDLINK from '../../assets/addlink.jpg';
import EDIFIER from '../../assets/edifier.jpg';
import GRIZZLY from '../../assets/grizzly_weblogo.png';
import HWAWEI from '../../assets/hwawei.jpeg';
import INTEL from '../../assets/intel.png';
import PALIT from '../../assets/palit.png';
import SAPPHIRETECH from '../../assets/sapphiretech.jpeg';
import TEAMGROUPINC from '../../assets/TEAMGROUPINC.png';

import { useTranslation } from 'react-i18next';

function AboutUs() {
  const { t } = useTranslation('about_us');

  const vendors = [
    {
      name: 'MSI',
      pic: MSI,
    },
    {
      name: 'ASUS',
      pic: ASUS,
    },
    {
      name: 'COOLERMASTER',
      pic: COOLERMASTER,
    },
    {
      name: 'KLEVV',
      pic: KLEVV,
    },
  ];

  const vendor2 = [
    {
      name: 'LEXAR',
      pic: LEXAR,
    },
    {
      name: 'Razer',
      pic: RAZER,
    },
    {
      name: 'Phillps',
      pic: PHILLPS,
    },
    {
      name: 'Hyper X',
      pic: HyperX,
    },
  ];

  const vendor3 = [
    {
      name: 'Logitech',
      pic: LOGITECH,
    },
    {
      name: 'Steelseries',
      pic: STEELSERIES,
    },
    {
      name: 'FIFINE',
      pic: FIFINE,
    },
    {
      name: 'BAYERDYNAMIC',
      pic: BAYERDYNAMIC,
    },
  ];

  const agents = [
    {
      name: 'MEETION',
      pic: MEETION,
      link: 'https://www.meetion.com/',
    },
    {
      name: 'DEEPCOOL',
      pic: DEEPCOOL,
      link: 'https://www.deepcool.com/wheretobuy/index.shtml',
    },
    {
      name: 'FIIO',
      pic: FIIO,
      link: 'https://www.fiio.com/wheretobuy',
    },
    {
      name: 'LIANLI',
      pic: LIANLI,
      link: 'https://lian-li.com/where-to-buy/',
    },
    {
      name: 'CREATIVE',
      pic: CREATIVE,
      link: 'https://creative.com/',
    },
  ];

  const agents2 = [
    {
      name: 'ADDLINK',
      pic: ADDLINK,
      link: 'https://www.addlink.com.tw/',
    },
    {
      name: 'EDIFIER',
      pic: EDIFIER,
      link: 'https://www.edifier.com/int/en',
    },
    {
      name: 'GRIZZLY',
      pic: GRIZZLY,
      link: 'https://www.thermal-grizzly.com/',
    },
    {
      name: 'HWAWEI',
      pic: HWAWEI,
      link: 'https://consumer.huawei.com/en/laptops/',
    },
    {
      name: 'INTEL',
      pic: INTEL,
      link: 'https://www.intel.com/content/www/us/en/homepage.html',
    },
  ];
  
  const agents3 = [
    {
      name: 'PALIT',
      pic: PALIT,
      link: 'https://www.palit.com/palit/wtbuy.php?lang=en&country_id=71&cate_id=1',
    },
    {
      name: 'SAPPHIRETECH',
      pic: SAPPHIRETECH,
      link: 'https://www.sapphiretech.com/en',
    },
    {
      name: 'TEAMGROUPINC',
      pic: TEAMGROUPINC,
      link: 'https://www.teamgroupinc.com/en/',
    }
  ];

  return (
    <div
      className="mb-5"
      style={{ backgroundColor: 'var(--color-background)' }}
    >
      <div>
        <h5 className="text-center pt-4 pb-2 text-4xl">{t('about-us')}</h5>
        <h2 className="text-center blue text-3xl">3D Electronics</h2>
        <div className="flex justify-center pt-8">
          <hr width="160" className="font-bold" />
        </div>
        <div className="py-8 md:px-40 px-5">
          <p className="text-center text-xl blue">
            {t('about-us-description-1')}
          </p>
        </div>
      </div>
      <div className="flex justify-center pb-4 w-full px-3">
        <ReactPlayer url={VEDIO} playing controls />
      </div>
      <div className="py-8 md:px-40 px-5">
        <p className="text-xl text-center blue">
          {t('about-us-description-2')}
        </p>
      </div>

      <h2 className="text-center blue mt-16">{t('our-vendors')}</h2>
      <span className="flex justify-center flex-wrap flex-1 pt-3 pb-3">
        {agents.map((vendor, i) => (
          <a href={vendor.link}>
            <div key={i} className="mx-2 text-center md:pl-1 my-3">
              <div
                style={{
                  width: '13rem',
                  height: '14rem',
                  background: 'var(--color-secondary)',
                }}
              >
                <div className="flex justify-center pt-4">
                  <img
                    alt=""
                    src={vendor.pic}
                    className="border rounded-full rounded-full h-32 w-32 "
                  />
                </div>
                <div className="block m-auto text-2xl">{vendor.name}</div>
              </div>
            </div>
          </a>
        ))}
      </span>
      <span className="flex justify-center flex-wrap flex-1 pt-3 pb-3">
        {agents2.map((vendor, i) => (
          <a href={vendor.link}>
            <div key={i} className="mx-2 text-center md:pl-1 my-3">
              <div
                style={{
                  width: '13rem',
                  height: '14rem',
                  background: 'var(--color-secondary)',
                }}
              >
                <div className="flex justify-center pt-4">
                  <img
                    alt=""
                    src={vendor.pic}
                    className="border rounded-full rounded-full h-32 w-32 "
                  />
                </div>
                <div className="block m-auto text-2xl">{vendor.name}</div>
              </div>
            </div>
          </a>
        ))}
      </span>
      <span className="flex justify-center flex-wrap flex-1 pt-3 pb-3">
        {agents3.map((vendor, i) => (
          <a href={vendor.link}>
            <div key={i} className="mx-2 text-center md:pl-1 my-3">
              <div
                style={{
                  width: '13rem',
                  height: '14rem',
                  background: 'var(--color-secondary)',
                }}
              >
                <div className="flex justify-center pt-4">
                  <img
                    alt=""
                    src={vendor.pic}
                    className="border rounded-full rounded-full h-32 w-32 "
                  />
                </div>
                <div className="block m-auto text-2xl">{vendor.name}</div>
              </div>
            </div>
          </a>
        ))}
      </span>
      <h2 className="text-center blue">{t('companies-we-work-with')}</h2>
      <span className="flex justify-center flex-wrap flex-1 ">
        {vendors.map((vendor, i) => (
          <div key={i} className="mx-2 text-center md:pl-1 my-3">
            <div
              style={{
                width: '13rem',
                height: '14rem',
                background: 'var(--color-secondary)',
              }}
            >
              <div className="flex justify-center pt-4">
                <img
                  alt=""
                  src={vendor.pic}
                  className="border rounded-full rounded-full h-32 w-32 "
                />
              </div>
              <div className="block m-auto text-2xl">{vendor.name}</div>
            </div>
          </div>
        ))}
      </span>
      <span className="flex justify-center flex-wrap flex-1">
        {vendor2.map((vendor, i) => (
          <div key={i} className="mx-2 text-center md:pl-1 my-3">
            <div
              style={{
                width: '13rem',
                height: '14rem',
                background: 'var(--color-secondary)',
              }}
            >
              <div className="flex justify-center pt-4">
                <img
                  alt=""
                  src={vendor.pic}
                  className="border rounded-full rounded-full h-32 w-32 "
                />
              </div>
              <div className="block m-auto text-2xl">{vendor.name}</div>
            </div>
          </div>
        ))}
      </span>
      <span className="flex justify-center flex-wrap flex-1">
        {vendor3.map((vendor, i) => (
          <div key={i} className="mx-2 text-center md:pl-1 my-3">
            <div
              style={{
                width: '13rem',
                height: '14rem',
                background: 'var(--color-secondary)',
              }}
            >
              <div className="flex justify-center pt-4">
                <img
                  alt=""
                  src={vendor.pic}
                  className="border rounded-full rounded-full h-32 w-32 "
                />
              </div>
              <div className="block m-auto text-2xl">{vendor.name}</div>
            </div>
          </div>
        ))}
      </span>

      {/* map */}
      <h2 className="text-center mt-5 pb-2 blue">{t('our-location')}</h2>
      <div className="flex justify-center pb-20 pt-2 px-3">
        <iframe
          title="3d"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3334.3623284232017!2d44.44573861515159!3d33.30934178081453!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1557810930fa23bb%3A0x1cfb8cfb59e9fe19!2s3D%20elcetronics!5e0!3m2!1sen!2siq!4v1601830128513!5m2!1sen!2siq"
          width="900"
          height="450"
          frameBorder="0"
          aria-hidden="false"
          tabIndex="0"
        />
      </div>
    </div>
  );
}

export default AboutUs;
