import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { searchProducts } from '../../../services/client';
import ProductListView from '../productsList/ProductListView';
import Spinner3D from '../../../common/spinner/Spinner3D';

function ProductSearchLists() {
  const [loading, isLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  let { productsName } = useParams();

  const searchedProductName = productsName;

  let { state } = useLocation();
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  const prevState = usePrevious(state);

  useEffect(() => {
    isLoading(true);
    if (typeof state !== 'undefined' && state !== prevState) {
      searchProducts(productsName, state.currentPage, 'createdAt').then(
        (res) => {
          setProducts(res.data.content);
          setTotalElements(res.data.totalElements);
          setCurrentPage(res.data.number);
          setItemsCountPerPage(res.data.size);
          isLoading(false);
        }
      );
    } else {
      searchProducts(productsName, pageNumber, 'createdAt').then((res) => {
        setProducts(res.data.content);
        setTotalElements(res.data.totalElements);
        setCurrentPage(res.data.number);
        setItemsCountPerPage(res.data.size);
        isLoading(false);
      });
    }
  }, [productsName, pageNumber]);

  if (loading) {
    return <Spinner3D />;
  }

  return (
    <div className="md:flex xl:px-16">
      <ProductListView
        totalElements={totalElements}
        setPageNumber={setPageNumber}
        currentPage={currentPage}
        itemsCountPerPage={itemsCountPerPage}
        products={products}
        searchBack={true}
        searchedProductName={searchedProductName}
      />
    </div>
  );
}

export default ProductSearchLists;
