import React, { useEffect, useState } from 'react';
import { getOrders, searchOrder } from '../../../services/client';
import MoreOrderInfo from './impl/MoreOrderInfo';
import Pagination3D from '../../../common/pagination/Pagination3D';
import Spinner3D from '../../../common/spinner/Spinner3D';
import AddExtraInfoOrder from './impl/AddExtraInfoOrder';
import Popup3D from '../../../common/popup/Popup3D';
import Button3D from '../../../common/layout/Button3D';
import { NotFoundErrorPage } from '../../../common/error3D';
import { OrderStateDropDown } from './impl/OrderStateDropDown';
import Moment from 'moment';
import Badge3D from '../../../common/layout/Badge3D';

const OrderTableAdmin = () => {
  const [searchInput, setSearchInput] = useState('');
  const [addExtraInfoOrderModel, setAddExtraInfoOrderModel] = useState({
    showModel: false,
    order: {},
  });
  const [moreOrderInfoModel, setMoreOrderInfoModel] = useState({
    showModel: false,
    order: {},
  });
  const [loading, isLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [orders, setOrders] = useState([]);
  const [refreshPage, setRefreshPage] = useState(0);
  const [showErrorSearchMin, setShowErrorSearchMin] = useState({
    message: '',
    show: false,
  });

  const handlePageChange = (pageNum) => {
    setPageNumber(pageNum - 1);
  };

  const refreshPageIfNoOrderFound = () => {
    setRefreshPage(Math.random());
    setSearchInput('');
  };

  const handleRowClick = (order) => {
    setMoreOrderInfoModel({
      showModel: !moreOrderInfoModel.showModel,
      order,
    });
  };

  const searchOrderByMobileNumber = () => {
    if (searchInput.length !== 11) {
      setShowErrorSearchMin({
        message: 'Please insert a real mobile number with 11 numbers!',
        show: true,
      });
      setTimeout(
        () => setShowErrorSearchMin({ message: '', show: false }),
        5000
      );
      return;
    }
    isLoading(true);
    searchOrder(searchInput, 0, 'createdAt').then((res) => {
      setOrders(res.data.content);
      setTotalElements(res.data.totalElements);
      setCurrentPage(res.data.number);
      setItemsCountPerPage(res.data.size);
      isLoading(false);
    });
  };

  useEffect(() => {
    isLoading(true);
    getOrders(pageNumber, 'createdAt').then((res) => {
      setOrders(res.data.content);
      setTotalElements(res.data.totalElements);
      setCurrentPage(res.data.number);
      setItemsCountPerPage(res.data.size);
      isLoading(false);
    });
  }, [pageNumber, refreshPage]);

  if (moreOrderInfoModel.showModel) {
    return (
      <MoreOrderInfo
        setMoreOrderInfoModel={setMoreOrderInfoModel}
        moreOrderInfoModel={moreOrderInfoModel}
      />
    );
  }

  if (addExtraInfoOrderModel.showModel) {
    return (
      <AddExtraInfoOrder
        setAddExtraInfoOrderModel={setAddExtraInfoOrderModel}
        addExtraInfoOrderModel={addExtraInfoOrderModel}
        setRefreshPage={setRefreshPage}
      />
    );
  }

  if (loading) {
    return <Spinner3D />;
  }

  if (totalElements === 0) {
    return (
      <NotFoundErrorPage
        textHeader="There are no Orders!"
        textButton="Go back to Orders!"
        onClick={refreshPageIfNoOrderFound}
      />
    );
  }

  return (
    <div className="grid grid-cols-7 gap-5 px-16">
      <OrderTableAction
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        searchOrderByMobileNumber={searchOrderByMobileNumber}
      />

      <div className="col-span-6">
        <div className="flex justify-between">
          <div>
            Total orders: {totalElements}
            {showErrorSearchMin.show && (
              <Badge3D variant="warning" text={showErrorSearchMin.message} />
            )}
          </div>
          <Pagination3D
            currentPage={currentPage}
            totalElements={totalElements}
            itemsCountPerPage={itemsCountPerPage}
            handlePageChange={handlePageChange}
          />
        </div>

        <table className="mt-3 table-fixed">
          <thead>
            <tr className="blue text-center">
              <th className="w-1/12 border-2 py-3 border-gray-500">#</th>
              <th className="w-3/12 border-2 py-3 border-gray-500">NAME</th>
              <th className="w-2/12 border-2 py-3 border-gray-500">MOBILE</th>
              <th className="w-2/12 border-2 py-3 border-gray-500">DATE</th>
              <th className="w-1/12 border-2 py-3 border-gray-500">CITY</th>
              <th className="w-1/12 border-2  py-3 border-gray-500">TOTAL</th>
              <th className="w-3/12 border-2 py-3 border-gray-500">
                ORDER STATE
              </th>
              <th className="w-1/12 border-2 py-3 border-gray-500">
                <Popup3D text="Add TO O" popupText="ADD TO ORDER" />
              </th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, i) => (
              <tr key={i}>
                <td
                  onClick={() => handleRowClick(order)}
                  className="text-center cursor-pointer border-2 px-2 py-3 border-gray-500"
                >
                  {i + 1 + itemsCountPerPage * pageNumber}
                </td>
                <td
                  className="cursor-pointer border-2 px-2 py-3 border-gray-500"
                  onClick={() => handleRowClick(order)}
                >
                  {order.name}
                </td>
                <td className="border-2 px-2 py-3 border-gray-500">
                  {order.mobileNumber}
                </td>
                <td
                  className="cursor-pointer border-2 px-2 py-3 border-gray-500"
                  onClick={() => handleRowClick(order)}
                >
                  {Moment(order.localDate).format('H:mm D/M/Y')}
                </td>
                <td
                  className="cursor-pointer border-2 px-2 py-3 border-gray-500"
                  onClick={() => handleRowClick(order)}
                >
                  {order.city}
                </td>
                <td
                  className="text-center cursor-pointer border-2 px-2 py-3 border-gray-500"
                  onClick={() => handleRowClick(order)}
                >
                  {order.totalAmount}
                </td>
                <td className="border-2 flex justify-center py-3 border-gray-500 flex flex-wrap content-center">
                  <OrderStateDropDown
                    orderState={order.orderState}
                    orderId={order.id}
                  />
                </td>
                <td className="border-2 px-2 py-3 border-gray-500">
                  <Button3D
                    variant="add-info-table"
                    onClick={() =>
                      setAddExtraInfoOrderModel({
                        showModel: !addExtraInfoOrderModel.showModel,
                        order,
                      })
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrderTableAdmin;

const OrderTableAction = ({
  searchInput,
  setSearchInput,
  searchOrderByMobileNumber,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    setSearchInput('');
  };

  return (
    <form className="grid-rows-none gap-2 py-5" onSubmit={handleSubmit}>
      <div className="pt-8">
        <input
          className="shadow appearance-none border rounded w-48 text-white-700 text-lg py-3 px-4 h-10"
          style={{
            backgroundColor: 'var(--color-background)',
            borderRadius: 150,
          }}
          id="search"
          type="text"
          placeholder="Phone number"
          onChange={(e) => setSearchInput(e.target.value)}
          value={searchInput}
        />
      </div>
      <div className="py-5 flex justify-center">
        <Button3D
          variant="admin-main"
          text="Search"
          type="submit"
          onClick={searchOrderByMobileNumber}
        />
      </div>
    </form>
  );
};
