import React, { createContext, useEffect, useState } from 'react';

export const OrderContext = createContext();

const isUUID = (uuid) => {
  return uuid.match(
    '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$'
  );
};

const OrderContextProvider = (props) => {
  const products = [];

  Object.keys(localStorage).forEach(function (key) {
    if (isUUID(key)) {
      products.push(JSON.parse(localStorage.getItem(key)));
    }
  });

  const [totalPrice, setTotalPrice] = useState(0);
  const [triggerCount, setTriggerCount] = useState(0);

  useEffect(() => {
    setTotalPrice(
      products.reduce(function (index, product) {
        return index + parseInt(product.price * product.count);
      }, 0)
    );
  }, [products, triggerCount]);

  return (
    <OrderContext.Provider
      value={{
        totalPrice,
        setTriggerCount,
        products,
      }}
    >
      {props.children}
    </OrderContext.Provider>
  );
};

export default OrderContextProvider;
