import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import './RecommendedProducts.css';
import { getRecommendedProducts } from '../../../services/client';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Spinner3D from '../../../common/spinner/Spinner3D';
import { useTranslation } from 'react-i18next';
import { CurrencyContext } from '../../../services/context/CurrencyContext';
import SlickArrowLeft from '../../../common/layout/SlickArrowLeft';
import SlickArrowRight from '../../../common/layout/SlickArrowRight';

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const RecommendedProducts = () => {
  const { t } = useTranslation('common');
  const [loading, isLoading] = useState(false);
  const [recommendedProducts, setRecommendedProducts] = useState([]);

  useEffect(() => {
    isLoading(true);
    getRecommendedProducts().then((res) => {
      setRecommendedProducts(res.data);
      isLoading(false);
    });
  }, []);

  const settings = {
    dots: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    nextArrow: <SlickArrowLeft />,
    prevArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 5,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
          arrows: false,
          infinite: true,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
          arrows: false,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          className: '',
          dots: false,
          arrows: false,
          infinite: true,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          className: '',
          dots: false,
          arrows: false,
          infinite: true,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          className: '',
          dots: false,
          arrows: false,
          infinite: true,
        },
      },
      {
        breakpoint: 310,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          className: '',
          dots: false,
          arrows: false,
          infinite: true,
        },
      },
    ],
  };

  if (loading) {
    return <Spinner3D />;
  }

  return (
    <div className="pt-5">
      <p
        className="titleRecommended  blue font-bold flex justify-center pt-4"
        style={{ fontSize: '130%' }}
      >
        {t('recommended-items')}
      </p>
      <div>
        <div>
          <Slider className="mx-8 flex justify-center" {...settings}>
            {shuffleArray(recommendedProducts).map((product, i) => (
              <RecommendedList key={i} recommendedProduct={product} />
            ))}
          </Slider>
          <Slider className="mx-8 flex justify-center" {...settings}>
            {shuffleArray(recommendedProducts).map((product, i) => (
              <RecommendedList key={i} recommendedProduct={product} />
            ))}
          </Slider>
          <div className="block md:hidden">
            <Slider
              className="md:hidden mx-8 flex justify-center"
              {...settings}
            >
              {shuffleArray(recommendedProducts).map((product, i) => (
                <RecommendedList key={i} recommendedProduct={product} />
              ))}
            </Slider>
          </div>
          <div className="block md:hidden">
            <Slider className=" mx-8 flex justify-center" {...settings}>
              {shuffleArray(recommendedProducts).map((product, i) => (
                <RecommendedList key={i} recommendedProduct={product} />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecommendedProducts;

const RecommendedList = ({ recommendedProduct }) => {
  const [price, setPrice] = useState(0);
  const currency = useContext(CurrencyContext);

  useEffect(() => {
    if (localStorage.getItem('money') === 'dollar') {
      setPrice(recommendedProduct.price);
    } else {
      setPrice(recommendedProduct.price * currency.currencyPrice);
    }
  }, [currency, recommendedProduct]);

  if (currency.loading) {
    return <Spinner3D />;
  }

  return (
    <RouterLink
      to={{
        pathname: `/product/${recommendedProduct.id}`,
        state: { productDetails: recommendedProduct, currentPage: -1 },
      }}
    >
      <div
        style={{ backgroundColor: 'var(--color-secondary)' }}
        className="card-recommended card-item h-card cat-width"
      >
        <img
          className="p-2 w-full object-cover block card-recommended-pic-h"
          src={recommendedProduct.picLocation}
          alt=""
        />
        <div className="flex justify-center flex-column items-center text-center mt-2 wrap-text-product-recommended">
          {recommendedProduct.name}
          <br />
          <div className="flex text-center justify-center">
            <div className="ml-2">{price.toLocaleString()}</div>
            <div className="ml-1">
              {localStorage.getItem('money') === 'dollar' ? '$' : 'IQD'}
            </div>
          </div>
        </div>
      </div>
    </RouterLink>
  );
};
