export const CONTENT_TYPE_JSON_VALUE = 'application/json';
export const CONTENT_TYPE_FORM_VALUE = 'multipart/form-data';

// API url
const API_URL = `api/`;

// Public url
const PUBLIC_URL = `${API_URL}resources/`;
// Management url
const MANAGEMENT_URL = `${API_URL}management/v1/`;

// Category url
export const CATEGORIES_URL = `${PUBLIC_URL}categories/`;
export const MANAGEMENT_CATEGORIES_URL = `${MANAGEMENT_URL}categories/`;
export const MANAGEMENT_CATEGORIES_ADD_URL = `${MANAGEMENT_CATEGORIES_URL}add-category/`;
export const MANAGEMENT_CATEGORIES_UPDATE_URL = `${MANAGEMENT_CATEGORIES_URL}update-category/`;

// Product url
export const MANAGEMENT_PRODUCTS_URL = `${MANAGEMENT_URL}products/`;
export const MANAGEMENT_PRODUCTS_RECOMMENDED_URL = `${MANAGEMENT_PRODUCTS_URL}recommended/`;
export const MAKE_PRODUCT_RECOMMENDED_OR_NOT_URL = `${MANAGEMENT_PRODUCTS_URL}make-recommended/`;
export const MAKE_PRODUCT_OUT_OF_STOCK_OR_NOT_URL = `${MANAGEMENT_PRODUCTS_URL}make-out-of-stock/`;
export const MAKE_PRODUCT_ONLY_SHOP_AVAILABLE_URL = `${MANAGEMENT_PRODUCTS_URL}make-only-shop-available/`;
export const DELETE_HOME_SCREEN_IMAGE_FOR_PRODUCT = `${MANAGEMENT_PRODUCTS_URL}delete-home-screen-image/`;
export const PRODUCTS_URL = `${PUBLIC_URL}products/`;
export const PRODUCTS_RECOMMENDED_URL = `${PRODUCTS_URL}recommended/`;

// Login url
export const LOGIN_URL = `${API_URL}login`;
export const USER = 'user';
export const ACCESS_TOKEN = 'accessToken';
export const AUTHORIZATION_VALUE = ` ${localStorage.getItem(ACCESS_TOKEN)}`;

// Order url
export const ORDER_URL = `${PUBLIC_URL}orders/`;
export const ADD_ORDER_URL = `${ORDER_URL}add-order/`;
export const CHECK_STATUS_ORDER_URL = `${ORDER_URL}checkStatus/`;

export const ORDER_URL_MANAGEMENT = `${MANAGEMENT_URL}orders/`;
export const ORDER_URL_MANAGEMENT_SEARCH = `${ORDER_URL_MANAGEMENT}search/`;
export const ORDER_URL_MANAGEMENT_ADD_EXTRA_INFO = `${ORDER_URL_MANAGEMENT}extra-info/`;

// Contact US url
export const CONTACT_US_URL = `${PUBLIC_URL}contact-us/`;

// Currency url
export const MANAGEMENT_CURRENCY_URL = `${MANAGEMENT_URL}currency/`;
export const CURRENCY_URL = `${PUBLIC_URL}currency/`;
