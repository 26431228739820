import React, { useState } from 'react';
import './OrderStatusPage.css';
import Box from '../../assets/svg/01.svg';
import Order from '../../assets/svg/02.svg';
import Truck from '../../assets/svg/03.svg';
import Done from '../../assets/svg/04.svg';
import DoneIcon from '@material-ui/icons/Done';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import { checkOrderStatus } from '../../services/client';
import { useHistory } from 'react-router-dom';
import Button3D from '../../common/layout/Button3D';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
import Badge3D from '../../common/layout/Badge3D';

const OrderStatusPage = () => {
  const { t, i18n } = useTranslation('common');
  const history = useHistory();
  const [orderSearchValue, setOrderSearchValue] = useState();
  const [showOrder, setShowOrder] = useState(false);
  const [notFoundOrderVisibleError, setNotFoundOrderVisibleError] = useState(
    false
  );
  const [tooManyRequestsError, setTooManyRequestsError] = useState(false);

  const [orders, setOrders] = useState([]);

  const check = (event) => {
    event.preventDefault();
    checkOrderStatus(orderSearchValue)
      .then((res) => {
        setOrders(res.data);
        setShowOrder(true);
        setOrderSearchValue('');
      })
      .catch((err) => {
        if (err.response.data.status === 429) {
          setTooManyRequestsError(true);
          setTimeout(() => {
            setTooManyRequestsError(false);
          }, 4000);
        } else {
          setNotFoundOrderVisibleError(true);
          setTimeout(() => {
            setNotFoundOrderVisibleError(false);
          }, 4000);
        }
      });
  };

  return (
    <div style={{ backgroundColor: 'var(--color-background)' }}>
      <div className="grid grid-rows-none justify-center items-center child">
        <form
          className="flex justify-center my-5 mx-auto shadow"
          onSubmit={check}
        >
          <input
            type="text"
            className="mr-10 px-3 border-2 h-10 w-56"
            style={{
              backgroundColor: 'var(--color-background)',
              borderRadius: 15,
            }}
            value={orderSearchValue || ''}
            onChange={(e) => setOrderSearchValue(e.target.value)}
          />
          <Button3D
            variant="order-status"
            text={t('follow')}
            onClick={check}
            type="submit"
          />
        </form>
        <div className="container flex justify-center">
          <Badge3D
            className="text-center mt-6"
            variant="danger-order-state"
            text={t('no-orders-found')}
            show={notFoundOrderVisibleError}
          />
          <Badge3D
            className="text-center mt-6"
            variant="danger-order-state"
            text={t('wait')}
            show={tooManyRequestsError}
          />
        </div>

        {showOrder ? (
          <div>
            <div className="flex justify-center">
              <h3 className="mt-5 blue ">
                <p dir={i18n.dir()}>
                  {t('hello')} {orders[0].name}
                </p>
              </h3>
            </div>
            {orders.map((order, i) => (
              <div className="mt-4 w-screen" key={i}>
                {order.orderState !== 'CANCELLED' && (
                  <div className="border-b border-dashed my-5 py-5 px-20">
                    <div className="flex justify-center">
                      <h6 className="mt-3 blue mb-4">
                        <div className="pb-2 flex justify-center">
                          {t('order-day')}
                        </div>
                        {Moment(order.createdAt).format('H:mm dddd D-M-Y')}
                      </h6>
                    </div>
                    <div className="mt-8 flex items-center justify-evenly">
                      <img
                        src={Box}
                        alt=""
                        className="md:w-16 w-12 h-12 md:h-16"
                      />
                      <img
                        src={Order}
                        alt=""
                        className="md:w-16 w-12 h-12 md:h-16"
                      />
                      <img
                        src={Truck}
                        alt=""
                        className="md:w-16 w-12 h-12 md:h-16"
                      />
                      <img
                        src={Done}
                        alt=""
                        className="md:w-16 w-12 h-12 md:h-16"
                      />
                    </div>
                    <div className="mt-5">
                      <ul className="flex justify-evenly -mx-5">
                        {order.orderState === 'RECEIVED' ||
                        order.orderState === 'IN_PROGRESS' ||
                        order.orderState === 'SHIPPED' ||
                        order.orderState === 'DELIVERED' ||
                        order.orderState === 'COMPLETED' ? (
                          <li className="border-2 z-50 status-green rounded-full w-8 h-8 text-center b-border">
                            <DoneIcon style={{ color: '#333' }} />
                          </li>
                        ) : (
                          <li className="border-2 z-50 bg-white rounded-full w-8 h-8 text-center b-border">
                            <TimelapseIcon className="blue animate-logo b-border" />
                          </li>
                        )}

                        {order.orderState === 'IN_PROGRESS' ||
                        order.orderState === 'SHIPPED' ||
                        order.orderState === 'DELIVERED' ||
                        order.orderState === 'COMPLETED' ? (
                          <li className="border-2 z-50 status-green rounded-full w-8 h-8 text-center b-border">
                            <DoneIcon style={{ color: '#333' }} />
                          </li>
                        ) : (
                          <li className="border-2 z-50 bg-white rounded-full w-8 h-8 text-center b-border">
                            <TimelapseIcon className="blue animate-logo b-border" />
                          </li>
                        )}

                        {order.orderState === 'SHIPPED' ||
                        order.orderState === 'DELIVERED' ||
                        order.orderState === 'COMPLETED' ? (
                          <li className="border-2 z-50 status-green rounded-full w-8 h-8 text-center b-border">
                            <DoneIcon style={{ color: '#333' }} />
                          </li>
                        ) : (
                          <li className="border-2 bg-white z-50 rounded-full w-8 h-8 text-center b-border">
                            <TimelapseIcon className="blue animate-logo b-border" />
                          </li>
                        )}
                        {order.orderState === 'DELIVERED' ||
                        order.orderState === 'COMPLETED' ? (
                          <li className="border-2 z-50 status-green rounded-full w-8 h-8 text-center b-border">
                            <DoneIcon style={{ color: '#333' }} />
                          </li>
                        ) : (
                          <li className="border-2 z-50 bg-white rounded-full w-8 h-8 text-center b-border">
                            <TimelapseIcon className="blue animate-logo b-border" />
                          </li>
                        )}
                      </ul>
                    </div>
                    <div className="mt-3 flex justify-evenly blue text-xs md:text-base -mx-5 ">
                      <p className="ml-4">{t('received')}</p>
                      <p className="mr-2">{t('in-progress')}</p>
                      <p className="mr-2">{t('shipped')}</p>
                      <p className="mr-8">{t('delivered')}</p>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="grid grid-rows-none justify-center">
            {/* order text */}
            <p className="mt-20 md:text-4xl text-center md:text-2xl">
              {t('use-your-number')}
            </p>
            <div className="flex justify-center md:mt-10 mt-4 mb-3">
              <Button3D
                onClick={() => history.goBack()}
                text={t('go-back-shopping')}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderStatusPage;
