import React, { useState } from 'react';
import Cross from '@material-ui/icons/Close';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { CategoryDropDownList } from './CategoryDropDownList';
import { UploadImage } from '../../../../common/formik/UploadImage';
import { FormikForm } from '../../../../common/formik/FormikForm';
import { errorAlert3D } from '../../../../common/error3D';
import { closeWindowAndRefreshComponent } from '../../../../common/common3D';
import Button3D from '../../../../common/layout/Button3D';
import Badge3D from '../../../../common/layout/Badge3D';
import { Editor } from '@tinymce/tinymce-react';
import Swal from 'sweetalert2';
import {
  deleteHomeScreenProductImage,
  editProduct
} from '../../../../services/client';

const validationSchema = yup.object({
  productName: yup
    .string()
    .test(
      'len',
      'Please add a name with more than 5 characters',
      (productName) => {
        if (productName) {
          return productName.toString().length >= 5;
        }
      }
    ),
  productPrice: yup
    .number()
    .typeError('you must specify a number')
    .positive('The price must be greater than zero')
    .required('Please add a price to the product!'),
  priceAssemble: yup
    .number()
    .typeError('You must specify a number')
    .required('Please add an assemble price to the product, or zero!'),
  categoryId: yup.string().required('Please add a category to the product!'),
});

const EditProduct = ({
  setEditProductModel,
  editProductModel,
  setRefreshPage,
}) => {
  const initialValues = {
    id: editProductModel.product.id,
    productName: editProductModel.product.name,
    productPrice: editProductModel.product.price,
    priceAssemble: editProductModel.product.priceAssemble,
    categoryId: editProductModel.product.category.id,
    productImages: [],
    recommended: editProductModel.product.recommended,
    sale: editProductModel.product.sale,
    comingSoon: editProductModel.product.comingSoon,
    outOfStock: editProductModel.product.outOfStock,
    onlyShopAvailable: editProductModel.product.onlyShopAvailable,
    description: editProductModel.product.description,
    picLocation: null,
    homeScreenPicLocation: editProductModel.product.homeScreenPicLocation,
  };

  const [recommended, setRecommended] = useState(
    editProductModel.product.recommended
  );

  const [comingSoon, setComingSoon] = useState(
    editProductModel.product.comingSoon
  );

  const [sale, setSale] = useState(editProductModel.product.sale);

  const [outOfStock, setOutOfStock] = useState(
    editProductModel.product.outOfStock
  );

  const [onlyShopAvailable, setOnlyShopAvailable] = useState(
    editProductModel.product.onlyShopAvailable
  );

  const [description, setDescription] = useState(
    editProductModel.product.description
  );

  const handleComingSoon = (e) => {
    setComingSoon(e.target.checked);
  };

  const handleSwitchRecommended = (e) => {
    setRecommended(e.target.checked);
  };

  const handleSwitchSale = (e) => {
    setSale(e.target.checked);
  };

  const handleSwitchOutOfStock = (e) => {
    setOutOfStock(e.target.checked);
  };

  const handleSwitchOnlyShopAvailable = (e) => {
    setOnlyShopAvailable(e.target.checked);
  };

  const handleEditorChange = (content, editor) => {
    setDescription(content);
  };


  const handleDeleteHomeScreenProductImage = (e) => {
    e.preventDefault();
    Swal.fire({
                title: "Are you sure to delete Home Screen Image for this" +
                  " product?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Yes, delete it!"
              }).then((result) => {
      if (result.isConfirmed) {
        deleteHomeScreenProductImage(initialValues.id)
          .then(() => {
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
          })
          .catch((err) => {
            errorAlert3D(err);
          });
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(product, { setSubmitting, resetForm }) => {
        const formData = new FormData();
        product.productImages.forEach((item) => {
          formData.append('productImages', item);
        });
        if (product.picLocation) {
          formData.append('picLocation', product.picLocation[0]);
        } else {
          formData.append('picLocation', new Blob());
        }
        if (product.homeScreenPicLocation) {
          formData.append(
            'homeScreenPicLocation',
            product.homeScreenPicLocation[0]
          );
        } else {
          formData.append('homeScreenPicLocation', new Blob());
        }
        formData.append('id', product.id);
        formData.append('productName', product.productName);
        formData.append('productPrice', product.productPrice);
        formData.append('priceAssemble', product.priceAssemble);
        formData.append('categoryId', product.categoryId);
        formData.append('description', description);
        formData.append('recommended', recommended);
        formData.append('sale', sale);
        formData.append('comingSoon', comingSoon);
        formData.append('outOfStock', outOfStock);
        formData.append('onlyShopAvailable', onlyShopAvailable);
        editProduct(formData)
          .then((res) => {
            resetForm({});
            setSubmitting(false);
            closeWindowAndRefreshComponent(
              setEditProductModel,
              editProductModel.showModel,
              setRefreshPage
            );
          })
          .catch((err) => {
            errorAlert3D(err);
            setSubmitting(false);
          });
      }}
    >
      {({ submitForm, isSubmitting, errors, touched, setFieldValue }) => (
        <Form className="container items-center z-40">
          <div
            className="text-center md:rounded-lg p-4 md:relative"
            style={{ backgroundColor: 'var(--color-secondary)' }}
          >
            <div className="flex justify-end model-p text-xl">
              <Cross
                className="cursor-pointer"
                onClick={() => setEditProductModel(!editProductModel.showModel)}
                width="20"
                height="20"
                alt="exit"
              />
            </div>
            <div className="px-56 text-xl">
              <div>
                <div className="flex justify-center model-p text-3xl">
                  Edit new Product
                </div>
                <div>
                  <FormikForm
                    as={'input'}
                    name="productName"
                    label="Product Name: "
                    errors={errors.productName}
                    touched={touched.productName}
                  />
                  <div className="py-2" />
                  <FormikForm
                    as={'input'}
                    name="productPrice"
                    label="Product Price: "
                    errors={errors.productPrice}
                    touched={touched.productPrice}
                  />
                  <div className="py-2" />
                  <FormikForm
                    as={'input'}
                    name="priceAssemble"
                    label="Product Assemble Price: "
                    errors={errors.priceAssemble}
                    touched={touched.priceAssemble}
                  />
                  <div className="py-2" />
                  <div className="flex justify-between model-p blue">
                    <label>Product Category:</label>
                    <Field
                      name="categoryId"
                      currentCategoryName={
                        editProductModel.product.category.categoryName
                      }
                      component={CategoryDropDownList}
                    />
                  </div>
                  <div className="pt-2 flex justify-end">
                    {errors.categoryId && touched.categoryId && (
                      <Badge3D variant="warning" text={errors.categoryId} />
                    )}
                  </div>
                  <div className="py-2" />
                  <div className="flex justify-between model-p blue">
                    <label>Is this product on Sale?</label>
                    <input
                      name="sale"
                      type="checkbox"
                      checked={sale}
                      onChange={handleSwitchSale}
                      className="m-1"
                    />
                  </div>
                  <div className="py-2" />
                  <div className="flex justify-between model-p blue">
                    <label>Is this product recommended?</label>
                    <input
                      name="recommended"
                      type="checkbox"
                      checked={recommended}
                      onChange={handleSwitchRecommended}
                      className="m-1"
                    />
                  </div>
                  <div className="py-2" />
                  <div className="flex justify-between model-p blue">
                    <label>Is this product Out Of Stock?</label>
                    <input
                      name="outOfStock"
                      type="checkbox"
                      checked={outOfStock}
                      onChange={handleSwitchOutOfStock}
                      className="m-1"
                    />
                  </div>
                  <div className="py-2" />
                  <div className="flex justify-between model-p blue">
                    <label>Is this product available only on shop?</label>
                    <input
                      name="onlyShopAvailable"
                      type="checkbox"
                      checked={onlyShopAvailable}
                      onChange={handleSwitchOnlyShopAvailable}
                      className="m-1"
                    />
                  </div>
                  <div className="py-2" />
                  <div className="flex justify-between model-p blue">
                    <label>Will this product be available soon?</label>
                    <input
                      name="comingSoon"
                      type="checkbox"
                      checked={comingSoon}
                      onChange={handleComingSoon}
                      className="m-1"
                    />
                  </div>
                  <div className="py-1" />
                  <div>
                    <div className="flex justify-between model-p blue">
                      <label>Product Main Image:</label>
                      <UploadImage
                        maxFiles={1}
                        popup="Only 1 image is allowed"
                        name="picLocation"
                        buttonText="Upload Main image"
                        setFieldValue={setFieldValue}
                      />
                    </div>
                    <div className="pt-2 flex justify-end">
                      {errors.picLocation && touched.picLocation && (
                        <Badge3D variant="warning" text={errors.picLocation} />
                      )}
                    </div>
                  </div>
                  <div className="py-1" />
                  <div>
                    <div className="flex justify-between model-p blue">
                      <label>Product Image:</label>
                      <UploadImage
                        maxFiles={5}
                        popup="Only 5 images are allowed"
                        name="productImages"
                        buttonText="Upload images"
                        setFieldValue={setFieldValue}
                      />
                    </div>
                    <div className="pt-2 flex justify-end">
                      {errors.productImages && touched.productImages && (
                        <Badge3D
                          variant="warning"
                          text={errors.productImages}
                        />
                      )}
                    </div>
                  </div>
                  <div className="py-1" />
                  <div>
                    <div className="flex justify-between model-p blue">
                      <label>Add to Home Screen Pictures?:</label>
                      <div className="flex justify-even">
                        {initialValues.homeScreenPicLocation && (
                          <div className="h-8 mr-3">
                            <Button3D
                            variant="delete-table"
                            onClick={handleDeleteHomeScreenProductImage}
                          />
                          </div>)
                        }
                        <UploadImage
                          maxFiles={1}
                          popup="Only 1 image is allowed"
                          name="homeScreenPicLocation"
                          buttonText="Upload HS image"
                          setFieldValue={setFieldValue}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="py-2" />
                  <label className="pb-1">Product Description:</label>
                  <Editor
                    initialValue={description}
                    apiKey="3blsqkfmp9wdcvg3d3fnadgr4h88eg1vngedxe4t5vavraue"
                    init={{
                      height: 500,
                      menubar: false,
                    }}
                    onEditorChange={handleEditorChange}
                  />
                  <div className="py-2" />
                  <div className="flex justify-center p-8">
                    <Button3D
                      variant="submit-button"
                      onClick={() => submitForm()}
                      disabled={isSubmitting}
                      text="Edit Product"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default EditProduct;
