import React, { useEffect, useState } from 'react';
import { getFilteredRecommendedProducts } from '../../../services/client';
import Pagination3D from '../../../common/pagination/Pagination3D';

import Spinner3D from '../../../common/spinner/Spinner3D';
import { NotFoundErrorPage } from '../../../common/error3D';

const RecommendedTableAdmin = ({ productsToggler }) => {
  const [loading, isLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [recommendedProducts, setRecommendedProducts] = useState([]);

  const handlePageChange = (pageNum) => {
    setPageNumber(pageNum - 1);
  };

  useEffect(() => {
    isLoading(true);
    getFilteredRecommendedProducts(pageNumber, 'createdAt').then((res) => {
      setRecommendedProducts(res.data.content);
      setTotalElements(res.data.totalElements);
      setCurrentPage(res.data.number);
      setItemsCountPerPage(res.data.size);
      isLoading(false);
    });
  }, [pageNumber]);

  if (loading) {
    return <Spinner3D />;
  }

  if (totalElements === 0) {
    return (
      <NotFoundErrorPage
        textHeader="here are no recommended items!"
        textButton="Please add recommended items!"
        onClick={() => productsToggler(true)}
      />
    );
  }

  return (
    <div className="grid grid-cols-3 gap-5 px-64">
      <div className="col-span-3">
        <div className="flex justify-between">
          <div>Total recommended products: {totalElements}</div>
          <Pagination3D
            currentPage={currentPage}
            totalElements={totalElements}
            itemsCountPerPage={itemsCountPerPage}
            handlePageChange={handlePageChange}
          />
        </div>
        <table className="mt-3 w-full table-fixed">
          <thead>
            <tr className="blue text-center">
              <th className="w-1/12 border-2 px-2 py-3 border-gray-500">#</th>
              <th className="w-7/12 border-2 px-2 py-3 border-gray-500">
                NAME
              </th>
              <th className="w-1/12 border-2 px-2 py-3 border-gray-500">IMG</th>
              <th className="w-1/12 border-2 px-2 py-3 border-gray-500">
                PRICE
              </th>
              <th className="w-1/12 border-2 px-2 py-3 border-gray-500">
                SALE
              </th>
            </tr>
          </thead>
          <tbody>
            {recommendedProducts.map((recommendedProduct, i) => (
              <tr key={i}>
                <td className="text-center cursor-pointer border-2 px-2 py-3 border-gray-500">
                  {i + 1 + itemsCountPerPage * pageNumber}
                </td>
                <td className="border-2 px-2 py-3 border-gray-500">
                  {recommendedProduct.name}
                </td>
                <td className="border-2 px-3 py-3 border-gray-500">
                  {recommendedProduct.imageJsonResponses[0] && (
                    <div className="flex justify-center">
                      <img
                        className="w-12 h-8"
                        src={
                          recommendedProduct.imageJsonResponses[0]
                            .productImageLocation
                        }
                        alt="rec"
                      />
                    </div>
                  )}
                </td>
                <td className="border-2 px-2 py-3 border-gray-500">
                  {recommendedProduct.price}
                </td>
                {recommendedProduct.sale ? (
                  <td className="text-green-500 border-2 px-2 py-3 border-gray-500">
                    Sale
                  </td>
                ) : (
                  <td className="text-red-500 border-2 px-2 py-3 border-gray-500">
                    Not Sale
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RecommendedTableAdmin;
