import React, { useContext } from 'react';
import './CategoryList.css';
import { Link as RouterLink } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CategoryContext } from '../../services/context/CategoryContext';
import Spinner3D from '../../common/spinner/Spinner3D';
import { useTranslation } from 'react-i18next';
import SlickArrowLeft from '../../common/layout/SlickArrowLeft';
import SlickArrowRight from '../../common/layout/SlickArrowRight';

function CategoryList() {
  const { t } = useTranslation('common');
  const { categoryList, loading } = useContext(CategoryContext);

  const settings = {
    dots: false,
    slidesToShow: 7,
    slidesToScroll: 7,
    nextArrow: <SlickArrowLeft />,
    prevArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 5,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
          arrows: false,
          infinite: true,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
          arrows: false,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          className: '',
          dots: false,
          arrows: false,
          infinite: true,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          className: '',
          dots: false,
          arrows: false,
          infinite: true,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          className: '',
          dots: false,
          arrows: false,
          infinite: true,
        },
      },
      {
        breakpoint: 310,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          className: '',
          dots: false,
          arrows: false,
          infinite: true,
        },
      },
    ],
  };

  const categoryViewList = categoryList.map((category, i) => (
    <RouterLink key={i} to={`/category/${category.id}`}>
      <div className="card-item h-card cat-width relative">
        <img
          className="w-full h-full object-cover block"
          src={category.categoryImageLocation}
          alt=""
        />
        <div className="text-img-cat text-white">{category.categoryName}</div>
      </div>
    </RouterLink>
  ));
  return (
    <div className="md:mt-8">
      <p
        className="titleCategory blue font-bold flex justify-center pt-4"
        style={{ fontSize: '130%' }}
      >
        {t('categories')}
      </p>
      {/* slider */}
      <div>
        {loading ? (
          <Spinner3D />
        ) : (
          <Slider className="mx-48 flex justify-center " {...settings}>
            {categoryViewList}
          </Slider>
        )}
      </div>
    </div>
  );
}

export default CategoryList;
