export const addProductToCart = (
  product,
  counter,
  showAddToCartConfirmation
) => {
  //check if product already exists in the localstorage
  if (localStorage.getItem(product.id)) {
    let productItem = JSON.parse(localStorage.getItem(product.id));
    let newCounter = counter + productItem.count;
    if (newCounter > 10) {
      newCounter = 10;
    }
    productItem = { ...productItem, count: newCounter };
    localStorage.setItem(product.id, JSON.stringify(productItem));
  } else {
    const newProduct = Object.assign({ count: counter }, product);
    localStorage.setItem(product.id, JSON.stringify(newProduct));
  }
  showAddToCartConfirmation(true);
};
