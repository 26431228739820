import React from 'react';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <div {...props}>
    <div className=" next-slick-arrow blue">
      <ArrowForwardIosIcon />
    </div>
  </div>
);

export default SlickArrowLeft;
