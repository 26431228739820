import {
  ADD_ORDER_URL,
  AUTHORIZATION_VALUE,
  CATEGORIES_URL,
  CHECK_STATUS_ORDER_URL,
  CONTACT_US_URL,
  CONTENT_TYPE_FORM_VALUE,
  CONTENT_TYPE_JSON_VALUE,
  CURRENCY_URL,
  DELETE_HOME_SCREEN_IMAGE_FOR_PRODUCT,
  LOGIN_URL,
  MAKE_PRODUCT_ONLY_SHOP_AVAILABLE_URL,
  MAKE_PRODUCT_OUT_OF_STOCK_OR_NOT_URL,
  MAKE_PRODUCT_RECOMMENDED_OR_NOT_URL,
  MANAGEMENT_CATEGORIES_ADD_URL,
  MANAGEMENT_CATEGORIES_UPDATE_URL,
  MANAGEMENT_CATEGORIES_URL,
  MANAGEMENT_CURRENCY_URL,
  MANAGEMENT_PRODUCTS_RECOMMENDED_URL,
  MANAGEMENT_PRODUCTS_URL,
  ORDER_URL_MANAGEMENT,
  ORDER_URL_MANAGEMENT_ADD_EXTRA_INFO,
  ORDER_URL_MANAGEMENT_SEARCH,
  PRODUCTS_RECOMMENDED_URL,
  PRODUCTS_URL
} from "./constants";
import Axios from 'axios';

const checkStatus = (response) => {
  if (response.status >= 200) {
    return response;
  } else {
    let error = new Error(response.statusText);
    error.response = response;
    response.then((e) => {
      error.error = e;
    });
    return Promise.reject(error);
  }
};

///////////////////////////////////////////// Categories /////////////////////////////////////////////
export const getCategories = () =>
  Axios.get(CATEGORIES_URL, {
    baseURL: '/',
    headers: {
      'Content-Type': CONTENT_TYPE_JSON_VALUE,
    },
  }).then(checkStatus);

export const getFilteredCategories = (page, sortBy) =>
  Axios.get(MANAGEMENT_CATEGORIES_URL + `?page=${page}&sortBy=${sortBy}`, {
    headers: {
      Authorization: AUTHORIZATION_VALUE,
      'Content-Type': CONTENT_TYPE_JSON_VALUE,
    },
  }).then(checkStatus);

export const addCategory = (category) =>
  Axios.post(MANAGEMENT_CATEGORIES_ADD_URL, category, {
    headers: {
      Authorization: AUTHORIZATION_VALUE,
      'Content-Type': CONTENT_TYPE_FORM_VALUE,
    },
  }).then(checkStatus);

export const updateCategory = (category) =>
  Axios.put(MANAGEMENT_CATEGORIES_UPDATE_URL, category, {
    headers: {
      Authorization: AUTHORIZATION_VALUE,
      'Content-Type': CONTENT_TYPE_FORM_VALUE,
    },
  }).then(checkStatus);

///////////////////////////////////////////// Products /////////////////////////////////////////////

export const getAllProducts = (page, sortBy) =>
  Axios.get(MANAGEMENT_PRODUCTS_URL + `?page=${page}&sortBy=${sortBy}`, {
    headers: {
      Authorization: AUTHORIZATION_VALUE,
      'Content-Type': CONTENT_TYPE_JSON_VALUE,
    },
  }).then(checkStatus);

export const makeProductRecommendedOrNot = (productId) =>
  Axios.get(MAKE_PRODUCT_RECOMMENDED_OR_NOT_URL + productId, {
    headers: {
      Authorization: AUTHORIZATION_VALUE,
      'Content-Type': CONTENT_TYPE_JSON_VALUE,
    },
  }).then(checkStatus);

export const makeProductOutOfStockOrNot = (productId) =>
  Axios.get(MAKE_PRODUCT_OUT_OF_STOCK_OR_NOT_URL + productId, {
    headers: {
      Authorization: AUTHORIZATION_VALUE,
      'Content-Type': CONTENT_TYPE_JSON_VALUE,
    },
  }).then(checkStatus);

export const makeProductOnlyShopAvailable = (productId) =>
  Axios.get(MAKE_PRODUCT_ONLY_SHOP_AVAILABLE_URL + productId, {
    headers: {
      Authorization: AUTHORIZATION_VALUE,
      'Content-Type': CONTENT_TYPE_JSON_VALUE,
    },
  }).then(checkStatus);

export const addProduct = (product) =>
  Axios.post(MANAGEMENT_PRODUCTS_URL, product, {
    headers: {
      Authorization: AUTHORIZATION_VALUE,
      'Content-Type': CONTENT_TYPE_FORM_VALUE,
    },
  }).then(checkStatus);

export const editProduct = (product) =>
  Axios.put(MANAGEMENT_PRODUCTS_URL, product, {
    headers: {
      Authorization: AUTHORIZATION_VALUE,
      'Content-Type': CONTENT_TYPE_FORM_VALUE,
    },
  }).then(checkStatus);

export const deleteProduct = (productId) =>
  Axios.delete(MANAGEMENT_PRODUCTS_URL + productId, {
    headers: {
      Authorization: AUTHORIZATION_VALUE,
      'Content-Type': CONTENT_TYPE_JSON_VALUE,
    },
  }).then(checkStatus);

export const getOneProduct = (productId) =>
  Axios.get(PRODUCTS_URL + `product/?productId=${productId}`, {
    baseURL: '/',
    headers: {
      'Content-Type': CONTENT_TYPE_JSON_VALUE,
    },
  }).then(checkStatus);

export const getProductsByCategory = (categoryId, page, sortBy) =>
  Axios.get(
    PRODUCTS_URL +
      `category-id/?categoryId=${categoryId}&page=${page}&sortBy=${sortBy}`,
    {
      baseURL: '/',
      headers: {
        'Content-Type': CONTENT_TYPE_JSON_VALUE,
      },
    }
  ).then(checkStatus);

export const getRecommendedProducts = () =>
  Axios.get(PRODUCTS_RECOMMENDED_URL, {
    headers: {
      'Content-Type': CONTENT_TYPE_JSON_VALUE,
    },
  }).then(checkStatus);

export const getFilteredRecommendedProducts = (page, sortBy) =>
  Axios.get(
    MANAGEMENT_PRODUCTS_RECOMMENDED_URL + `?page=${page}&sortBy=${sortBy}`,
    {
      headers: {
        Authorization: AUTHORIZATION_VALUE,
        'Content-Type': CONTENT_TYPE_JSON_VALUE,
      },
    }
  ).then(checkStatus);

export const searchProducts = (productName, page, sortBy) =>
  Axios.get(
    PRODUCTS_URL +
      `search/?productName=${productName}&page=${page}&sortBy=${sortBy}`,
    {
      baseURL: '/',
      headers: {
        'Content-Type': CONTENT_TYPE_JSON_VALUE,
      },
    }
  ).then(checkStatus);

export const deleteHomeScreenProductImage = (productId) =>
  Axios.delete(DELETE_HOME_SCREEN_IMAGE_FOR_PRODUCT + productId, {
    headers: {
      Authorization: AUTHORIZATION_VALUE,
      'Content-Type': CONTENT_TYPE_JSON_VALUE,
    },
  }).then(checkStatus);

///////////////////////////////////////////// Login /////////////////////////////////////////////

export const login = (loginInfo) =>
  Axios.post(LOGIN_URL, loginInfo, {
    headers: {
      'Content-Type': CONTENT_TYPE_JSON_VALUE,
    },
  }).then(checkStatus);

///////////////////////////////////////////// Order /////////////////////////////////////////////

export const addOrder = (order) =>
  Axios.post(ADD_ORDER_URL, order, {
    headers: {
      'Content-Type': CONTENT_TYPE_JSON_VALUE,
    },
  }).then(checkStatus);

export const getOrders = (page, sortBy) =>
  Axios.get(ORDER_URL_MANAGEMENT + `?page=${page}&sortBy=${sortBy}`, {
    headers: {
      Authorization: AUTHORIZATION_VALUE,
      'Content-Type': CONTENT_TYPE_JSON_VALUE,
    },
  }).then(checkStatus);

export const searchOrder = (mobileNumber, page, sortBy) =>
  Axios.get(
    ORDER_URL_MANAGEMENT_SEARCH +
      `?mobileNumber=${mobileNumber}&page=${page}&sortBy=${sortBy}`,
    {
      headers: {
        Authorization: AUTHORIZATION_VALUE,
        'Content-Type': CONTENT_TYPE_JSON_VALUE,
      },
    }
  ).then(checkStatus);

export const checkOrderStatus = (orderTrackId) =>
  Axios.get(CHECK_STATUS_ORDER_URL + orderTrackId, {
    headers: {
      'Content-Type': CONTENT_TYPE_JSON_VALUE,
    },
  }).then(checkStatus);

export const changeOrderState = (newOrderState) =>
  Axios.post(ORDER_URL_MANAGEMENT, newOrderState, {
    headers: {
      Authorization: AUTHORIZATION_VALUE,
      'Content-Type': CONTENT_TYPE_JSON_VALUE,
    },
  }).then(checkStatus);

export const addExtraInfoOrder = (extraInfoOrder) =>
  Axios.put(ORDER_URL_MANAGEMENT_ADD_EXTRA_INFO, extraInfoOrder, {
    headers: {
      Authorization: AUTHORIZATION_VALUE,
      'Content-Type': CONTENT_TYPE_JSON_VALUE,
    },
  }).then(checkStatus);

///////////////////////////////////////// Contact US ///////////////////////////

export const sendContactUsEmail = (message) =>
  Axios.post(CONTACT_US_URL, message, {
    headers: {
      'Content-Type': CONTENT_TYPE_JSON_VALUE,
    },
  }).then(checkStatus);

///////////////////////////////////////// Currency ///////////////////////////

export const submitNewCurrencyPrice = (currencyPrice) =>
  Axios.post(MANAGEMENT_CURRENCY_URL + currencyPrice, null, {
    headers: {
      Authorization: AUTHORIZATION_VALUE,
      'Content-Type': CONTENT_TYPE_JSON_VALUE,
    },
  }).then(checkStatus);

export const getNewCurrencyPrice = () =>
  Axios.get(CURRENCY_URL, {
    baseURL: '/',
    headers: {
      'Content-Type': CONTENT_TYPE_JSON_VALUE,
    },
  }).then(checkStatus);

//////////////////////////////////Home Page Images ///////////////////////////

export const getHomePageImages = () =>
  Axios.get(PRODUCTS_URL + `product-home-screen-images`, {
    baseURL: '/',
    headers: {
      'Content-Type': CONTENT_TYPE_JSON_VALUE,
    },
  }).then(checkStatus);
