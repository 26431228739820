import React from 'react';
import CounterInput from 'react-counter-input';

const Counter3D = ({ count, onCountChange }) => {
  return (
    <div className="md:mr-4 flex justify-center border-2 rounded-md b-border w-counter h-12 hover:bg-blue-900">
      <CounterInput
        count={count}
        btnStyle={{ padding: '30px' }}
        inputStyle={{ height: '100%' }}
        min={1}
        max={10}
        onCountChange={onCountChange}
      />
    </div>
  );
};

export default Counter3D;
