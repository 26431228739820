import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top';
import HomePage from './components/HomePage';
import Navbar from './common/header/Navbar';
import Footer from './common/footer/Footer';
import ProductCategoryLists from './components/product/productsCategory/ProductCategoryLists';
import ProductDetails from './components/product/productDetails/ProductDetails';
import ShoppingCart from './components/shopping-cart/ShoppingCart';
import OrderContextProvider from './services/context/OrderContext';
import ContactUs from './components/contact-us/ContactUs';
import OrderStatusPage from './components/order-status/OrderStatusPage';
import LoginPage from './components/admin/login/LoginPage';
import AboutUs from './components/about-us/AboutUs';
import CategoryContextProvider from './services/context/CategoryContext';
import './App.css';
import OrderPage from './components/order/OrderPage';
import NotFoundPage from './components/not-found-page/NotFoundPage';
import ManagementRoutes from './ManagementRoutes';
import LoginContextProvider from './services/context/LoginContext';
import ProductSearchLists from './components/product/search/ProductSearchLists';
import CurrencyContextProvider from './services/context/CurrencyContext';
import CategoryPageList from './components/categorie/CategoryPageList';
import RecommendedProductsPageList from './components/product/recommendedProducts/RecommendedProductsPageList';

function App() {
  return (
    <Router>
      <div className="main-cont">
        <ScrollToTop>
          <Navbar />
          <Switch>
            <Route exact path="/">
              <CategoryContextProvider>
                <HomePage />
              </CategoryContextProvider>
            </Route>
            <Route exact path="/product/:productId">
              <OrderContextProvider>
                <CurrencyContextProvider>
                  <ProductDetails />
                </CurrencyContextProvider>
              </OrderContextProvider>
            </Route>
            <Route exact path="/category/:categoryId">
              <CategoryContextProvider>
                <CurrencyContextProvider>
                  <ProductCategoryLists />
                </CurrencyContextProvider>
              </CategoryContextProvider>
            </Route>
            <Route exact path="/category-list">
              <CategoryContextProvider>
                <CategoryPageList />
              </CategoryContextProvider>
            </Route>
            <Route exact path="/recommended-list">
              <RecommendedProductsPageList />
            </Route>
            <Route exact path="/search-products/:productsName">
              <CategoryContextProvider>
                <CurrencyContextProvider>
                  <ProductSearchLists />
                </CurrencyContextProvider>
              </CategoryContextProvider>
            </Route>
            <Route exact path="/follow-order">
              <OrderStatusPage />
            </Route>
            <Route exact path="/make-order">
              <OrderContextProvider>
                <CurrencyContextProvider>
                  <OrderPage />
                </CurrencyContextProvider>
              </OrderContextProvider>
            </Route>
            <Route exact path="/contact-us">
              <ContactUs />
            </Route>
            <Route exact path="/about-us">
              <AboutUs />
            </Route>
            <Route exact path="/shopping-cart">
              <OrderContextProvider>
                <CurrencyContextProvider>
                  <ShoppingCart />
                </CurrencyContextProvider>
              </OrderContextProvider>
            </Route>
            <Route exact path="/login">
              <LoginPage />
            </Route>
            <Route path="/404">
              <NotFoundPage />
            </Route>
            <LoginContextProvider>
              <ManagementRoutes />
            </LoginContextProvider>
          </Switch>
        </ScrollToTop>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
