import React from 'react';
import Swal from 'sweetalert2';

export const errorAlert3D = (err) => {
  if (err.response.data.status === 409) {
    Swal.fire('!المعذرة', err.response.data.errorMessage, 'warning');
  } else {
    Swal.fire('!المعذرة', '!لقد حصل خطأ ما، الرجاء المحاولة لاحقا', 'error');
  }
};

export const NotFoundErrorPage = ({ textHeader, textButton, onClick }) => {
  return (
    <div className="justify-center text-center pt-5">
      <p className="text-4xl pb-4">{textHeader}</p>
      <button
        className="border-2 rounded-md hover:bg-blue-900 bg-blue-600 text-white w-56 h-16"
        onClick={onClick}
      >
        {textButton}
      </button>
    </div>
  );
};
