import React, { createContext, useEffect, useState } from 'react';

import { getCategories } from '../client';

export const CategoryContext = createContext();

const CategoryContextProvider = (props) => {
  const [loading, isLoading] = useState(false);
  const [categoryList, setCategoriesList] = useState([]);

  useEffect(() => {
    isLoading(true);
    getCategories().then((res) => {
      setCategoriesList(res.data);
      isLoading(false);
    });
  }, []);

  return (
    <CategoryContext.Provider
      value={{
        categoryList,
        loading,
      }}
    >
      {props.children}
    </CategoryContext.Provider>
  );
};

export default CategoryContextProvider;
