import React from 'react';
import CategoryList from './categorie/CategoryList';
import HomePageSlider from '../common/homepageslider/HomePageSlider';
import RecommendedProducts from './product/recommendedProducts/RecommendedProducts';
import CurrencyContextProvider from '../services/context/CurrencyContext';
import SearchProducts from './product/search/SearchProducts';

function HomePage() {
  return (
    <div>
      <div className="md:block hidden">
        <HomePageSlider />
      </div>
      <div
        className="pb-12"
        style={{ backgroundColor: 'var(--color-background)' }}
      >
        <div className="pt-4 flex justify-center md:hidden">
          <SearchProducts />
        </div>
        <CategoryList />
        <CurrencyContextProvider>
          <RecommendedProducts />
        </CurrencyContextProvider>
      </div>
    </div>
  );
}

export default HomePage;
