import React from 'react';
import { Field } from 'formik';
import Badge3D from '../layout/Badge3D';

export const FormikForm = (props) => {
  return (
    <div>
      <div className="">
        <div>
          <label>{props.label}</label>
        </div>
        <div className="pt-2">
          <Field
            className="mr-3 px-3 shadow appearance-none border text-black rounded h-10 w-full"
            name={props.name}
            type={props.type}
            as={props.as}
          />
        </div>
      </div>
      <div className="pt-2 flex justify-end">
        {props.errors && props.touched && (
          <Badge3D variant="warning" text={props.errors} />
        )}
      </div>
    </div>
  );
};
