import React from 'react';
import Cross from '@material-ui/icons/Close';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { updateCategory } from '../../../../services/client';
import { FormikForm } from '../../../../common/formik/FormikForm';
import { UploadImage } from '../../../../common/formik/UploadImage';
import { errorAlert3D } from '../../../../common/error3D';
import Badge3D from '../../../../common/layout/Badge3D';

const validationSchema = yup.object({
  categoryName: yup.string().required('Please add a category name!'),
});

const closeWindow = (setEditModel, editModel, setRefreshPage) => {
  setEditModel(!editModel);
  setRefreshPage(Math.random());
};

const EditCategory = ({
  setCategoryEditModel,
  editCategoryModel,
  setRefreshPage,
}) => {
  const initialValues = {
    id: editCategoryModel.category.id,
    categoryName: editCategoryModel.category.categoryName,
    categoryImage: editCategoryModel.category.categoryImage,
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(category, { setSubmitting, resetForm }) => {
        const formData = new FormData();
        if (category.categoryImage) {
          formData.append('categoryImage', category.categoryImage[0]);
        } else {
          formData.append('categoryImage', new Blob());
        }
        formData.append('id', category.id);
        formData.append('categoryName', category.categoryName);
        updateCategory(formData)
          .then(() => {
            resetForm({});
            setSubmitting(false);
            closeWindow(
              setCategoryEditModel,
              editCategoryModel,
              setRefreshPage
            );
          })
          .catch((err) => {
            errorAlert3D(err);
            setSubmitting(false);
          });
      }}
    >
      {({ submitForm, isSubmitting, errors, touched, setFieldValue }) => (
        <Form className="container items-center z-40">
          <div
            className="text-center md:rounded-lg p-4 md:relative"
            style={{ backgroundColor: 'var(--color-secondary)' }}
          >
            <div className="flex justify-end model-p text-xl">
              <Cross
                className="cursor-pointer"
                onClick={() => setCategoryEditModel(!editCategoryModel)}
                width="20"
                height="20"
                alt="exit"
              />
            </div>
            <div className="px-56 text-xl">
              <div>
                <div className="flex justify-center model-p text-lg my-10 text-2xl">
                  Edit new Category
                </div>
                <FormikForm
                  name="categoryName"
                  label="Category Name: "
                  errors={errors.categoryName}
                  touched={touched.categoryName}
                  as={'input'}
                />
                <div className="py-4" />
                <div>
                  <div className="flex justify-around">
                    <label>Category Image:</label>
                    <UploadImage
                      maxFiles={1}
                      popup="Only 1 image is allowed"
                      name="categoryImage"
                      buttonText="Upload image"
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  <div className="pt-2 flex justify-end">
                    {errors.categoryImage && touched.categoryImage && (
                      <Badge3D variant="warning" text={errors.categoryImage} />
                    )}
                  </div>
                </div>
                <div className="flex justify-center p-8">
                  <Field
                    className="bg-blue border text-white rounded-lg p-2 w-40 text-sm"
                    onClick={() => submitForm()}
                    disabled={isSubmitting}
                    as={'button'}
                  >
                    Edit Category
                  </Field>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default EditCategory;
