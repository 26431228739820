import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Ribbon3D from '../../../../common/layout/Ribbon3D';
import Button3D from '../../../../common/layout/Button3D';
import { addProductToCart } from '../../common/addProductToCart';
import { useTranslation } from 'react-i18next';
import { CurrencyContext } from '../../../../services/context/CurrencyContext';
import Spinner3D from '../../../../common/spinner/Spinner3D';
import AddProductToCartModal from '../../common/AddProductToCartModal';

const Product = ({ product, currentPage, searchBack, searchedProductName }) => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation('product_cards');
  const [price, setPrice] = useState();
  const currency = useContext(CurrencyContext);

  const showAddToCartConfirmation = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (localStorage.getItem('money') === 'dollar') {
      setPrice(product.price);
    } else {
      setPrice(product.price * currency.currencyPrice);
    }
  }, [currency, product]);

  if (currency.loading) {
    return <Spinner3D />;
  }

  return (
    <div
      className="lg:w-1/4 md:w-1/2 p-4 w-full my-2"
      style={{ backgroundColor: 'var(--color-secondary)' }}
    >
      {price && (
        <div>
          <div className="block relative h-56 rounded overflow-hidden">
            <RouterLink
              to={{
                pathname: `/product/${product.id}`,
                state: {
                  productDetails: product,
                  currentPage: currentPage,
                  searchBack: searchBack,
                  searchedProductName: searchedProductName,
                },
              }}
            >
              <img
                alt="ecommerce"
                className="object-cover object-center w-full h-full block"
                src={product.picLocation}
              />
            </RouterLink>
            <div>
              {product.comingSoon ? (
                <Ribbon3D text={t('coming-soon')} variant="light-red" />
              ) : product.outOfStock ? (
                <Ribbon3D text={t('unavailable')} variant="red" />
              ) : product.onlyShopAvailable ? (
                <Ribbon3D text={t('only-on-shop')} variant="very-red" />
              ) : product.sale ? (
                <Ribbon3D text={t('sale')} variant="yellow-lrt" />
              ) : null}
            </div>
          </div>
          <div className="mt-4">
            <div className="flex justify-between">
              <h3 className="text-gray-200 text-xs tracking-widest title-font mb-1">
                {product.category.categoryName}
              </h3>
              {!product.comingSoon &&
                !product.outOfStock &&
                !product.onlyShopAvailable && (
                  <p className="flex text-2xl">
                    {price.toLocaleString()}
                    <div className="ml-2">
                      {localStorage.getItem('money') === 'dollar' ? '$' : 'IQD'}
                    </div>
                  </p>
                )}
            </div>

            {product.onlyShopAvailable || product.outOfStock || product.comingSoon  ? (
              <h2 className="title-font text-2xl font-bold wrap-text-product-list pt-8">
                {product.name}
              </h2>
            ) : (
              <div>
                <h2 className="title-font text-2xl font-bold wrap-text-product-list">
                  {product.name}
                </h2>
                <div className="flex justify-end">
                  <Button3D
                    onClick={(e) => {
                      e.preventDefault();
                      addProductToCart(product, 1, showAddToCartConfirmation);
                      setShowModal(!showModal);
                    }}
                    variant="add-shopping-cart"
                    text={t('add')}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {
        <AddProductToCartModal
          open={showModal}
          close={showAddToCartConfirmation}
        />
      }
    </div>
  );
};

export default Product;
