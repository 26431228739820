import React from 'react';
import './Spinner3D.css';

const Spinner3D = () => {
  return (
    <div className="spinner">
      <div className="loader ease-linear rounded-full border-20 border-t-8 border-gray-200 h-32 w-32" />
    </div>
  );
};

export default Spinner3D;
