import React from 'react';

const TotalPrice = ({ totalPrice, city }) => {
  return (
    <strong>
      {localStorage.getItem('money') === 'dollar' ? (
        <div className="flex justify-center">
          <div className="mr-2">{totalPrice.toLocaleString()}</div>
          <div className="mr-2">$</div>
          <span className="mr-2">
            {city === 'Baghdad'
              ? '+ 5000 IQD'
              : city === 'Baghdad Outskirts'
              ? '+ 8000 IQD'
              : city === ''
              ? ''
              : '+ 10000 IQD'}
          </span>
        </div>
      ) : (
        <span>
          {city === 'Baghdad' ? (
            <div className="flex justify-center">
              <div className="mr-2">{(totalPrice + 5000).toLocaleString()}</div>
              IQD
            </div>
          ) : city === 'Baghdad Outskirts' ? (
            <div className="flex justify-center">
              <div className="mr-2">{(totalPrice + 8000).toLocaleString()}</div>
              IQD
            </div>
          ) : city === '' ? (
            <div className="flex justify-center">
              <div className="mr-2">{totalPrice.toLocaleString()}</div>
              IQD
            </div>
          ) : (
            <div className="flex justify-center">
              <div className="mr-2">
                {(totalPrice + 10000).toLocaleString()}
              </div>
              IQD
            </div>
          )}
        </span>
      )}
    </strong>
  );
};

export default TotalPrice;
