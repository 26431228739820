import React, { useContext, useEffect, useState } from 'react';
import './ShoppingCart.css';
import { OrderContext } from '../../services/context/OrderContext';
import { Link as RouterLink } from 'react-router-dom';
import Counter3D from '../../common/counter/Counter3D';
import Button3D from '../../common/layout/Button3D';
import { useTranslation } from 'react-i18next';
import ShowMoreText from 'react-show-more-text';
import { CurrencyContext } from '../../services/context/CurrencyContext';
import Spinner3D from '../../common/spinner/Spinner3D';

function ShoppingCartItem({ productItem }) {
  const { t } = useTranslation('common');
  const [productCounter, setProductCounter] = useState(productItem.count);
  const order = useContext(OrderContext);

  const [price, setPrice] = useState(0);
  const currency = useContext(CurrencyContext);

  useEffect(() => {
    if (localStorage.getItem('money') === 'dollar') {
      setPrice(productItem.price);
    } else {
      setPrice(productItem.price * currency.currencyPrice);
    }
  }, [currency, productItem]);

  const updateProductCount = (newCount) => {
    setProductCounter(newCount);
    order.setTriggerCount(newCount);
    productItem = { ...productItem, count: newCount };
    localStorage.setItem(productItem.id, JSON.stringify(productItem));
  };

  const deleteProductFromCart = (productId) => {
    window.location.reload();
    localStorage.removeItem(productId);
  };

  if (currency.loading) {
    return <Spinner3D />;
  }

  return (
    <div
      className="card-deal mb-3 shadow-lg "
      style={{
        backgroundColor: 'var(--color-secondary)',
      }}
    >
      <div className="shadow-md min-w-full">
        <div className="md:flex md:justify-center py-5">
          {/* img */}
          <span className="p-10 flex justify-center">
            <img className="w-pj" src={productItem.picLocation} alt="" />
          </span>
          {/* name and details */}
          <RouterLink
            style={{ textDecoration: 'inherit', color: '#015c92' }}
            to={{
              pathname: `/product/${productItem.id}`,
              state: { productDetails: productItem, currentPage: -1 },
            }}
          >
            <div className="px-10">
              <span className="py-4 px-4 max-h-screen">
                <span className=" md:block ">
                  <h4 className="hidden md:block w-56 text-left font-bold">
                    {productItem.name}
                  </h4>
                  <div className="hidden md:block">
                    <div className="font-bold text-xl flex justify-start">
                      <div>{price}</div>
                      <div className="ml-1">
                        {localStorage.getItem('money') === 'dollar'
                          ? '$'
                          : 'IQD'}
                      </div>
                    </div>
                  </div>
                  <h4 className=" block md:hidden text-center font-bold">
                    {productItem.name}
                  </h4>
                  <p className="hidden md:block text-left pt-1 mr-2 h w overflow-auto">
                    <ShowMoreText lines={3} anchorClass="show-more ">
                      {productItem.description}
                    </ShowMoreText>
                  </p>
                  <p className="flex justify-center md:hidden text-center pt-2  h w-shop overflow-y-auto">
                    <ShowMoreText lines={3} anchorClass="show-more ">
                      {productItem.description}
                    </ShowMoreText>
                  </p>
                </span>
              </span>
            </div>
          </RouterLink>
          {/* price */}
          <div className="md:border-l-2 px-10">
            <span className="px-1 md:pt-3 md:flex justify-end">
              <div className="font-bold text-l price-txt  md:hidden">
                <div className="font-bold flex justify-center">
                  {price.toLocaleString()}
                  <div className="ml-2">
                    {localStorage.getItem('money') === 'dollar' ? '$' : 'IQD'}
                  </div>
                </div>
              </div>
              <div className="text-l md:block">
                <div className="flex justify-center text-2xl pt-2 font-bold">
                  <div>{(price * productCounter).toLocaleString()}</div>
                  <fiv className="ml-2">
                    {localStorage.getItem('money') === 'dollar' ? '$' : 'IQD'}
                  </fiv>
                </div>
              </div>
            </span>
            {/* counter */}
            <div className="flex justify-between md:mt-2 md:pt-10">
              <Counter3D
                count={productCounter}
                onCountChange={updateProductCount}
              />
              <Button3D
                variant="shopping-cart-items-delete"
                text={t('delete-from-cart')}
                onClick={() => deleteProductFromCart(productItem.id)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShoppingCartItem;
