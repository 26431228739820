import React, { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import EditProduct from './impl/EditProduct';
import CreateProduct from './impl/CreateProduct';
import {
  deleteProduct,
  getAllProducts,
  getProductsByCategory,
  makeProductOnlyShopAvailable,
  makeProductOutOfStockOrNot,
  makeProductRecommendedOrNot,
  searchProducts,
} from '../../../services/client';
import MoreProductInfo from './impl/MoreProductInfo';
import Pagination3D from '../../../common/pagination/Pagination3D';
import Spinner3D from '../../../common/spinner/Spinner3D';
import Popup3D from '../../../common/popup/Popup3D';
import { errorAlert3D, NotFoundErrorPage } from '../../../common/error3D';
import Button3D from '../../../common/layout/Button3D';
import { CategoryDropDownList } from './impl/CategoryDropDownList';
import Badge3D from '../../../common/layout/Badge3D';

const ProductsTableAdmin = () => {
  const [moreProductInfoModel, setMoreProductInfoModel] = useState({
    showModel: false,
    product: {},
  });
  const [loading, isLoading] = useState(false);
  const [editProductModel, setEditProductModel] = useState({
    showModel: false,
    product: {},
  });
  const [createModel, setCreateModel] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [refreshPage, setRefreshPage] = useState(0);
  const [products, setProducts] = useState([]);
  const [searchProductInput, setSearchProductInput] = useState('');
  const [showErrorSearchMin, setShowErrorSearchMin] = useState({
    message: '',
    show: false,
  });
  const [categoryId, setCategoryId] = useState('');

  const refreshPageIfNoOrderFound = () => {
    setRefreshPage(Math.random());
    setSearchProductInput('');
  };

  const handlePageChange = (pageNum) => {
    setPageNumber(pageNum - 1);
  };

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevSearch = usePrevious(searchProductInput);

  useEffect(() => {
    if (categoryId) {
      getProductsByCategory(categoryId, pageNumber, 'createdAt').then((res) => {
        setTotalElements(res.data.totalElements);
        setProducts(res.data.content);
        isLoading(false);
        setCurrentPage(res.data.number);
        setItemsCountPerPage(res.data.size);
      });
    } else if (searchProductInput) {
      if (searchProductInput.length < 3) {
        setShowErrorSearchMin({
          message: 'Please insert 3 letters or more!',
          show: true,
        });
        setTimeout(
          () => setShowErrorSearchMin({ message: '', show: false }),
          3000
        );
      } else {
        isLoading(true);
        if (prevSearch !== searchProductInput) {
          setPageNumber(0);
        }
        searchProducts(searchProductInput, pageNumber, 'createdAt').then(
          (res) => {
            setProducts(res.data.content);
            setTotalElements(res.data.totalElements);
            setCurrentPage(res.data.number);
            setItemsCountPerPage(res.data.size);
            isLoading(false);
          }
        );
      }
    } else {
      isLoading(true);
      getAllProducts(pageNumber, 'createdAt').then((res) => {
        setProducts(res.data.content);
        setTotalElements(res.data.totalElements);
        setCurrentPage(res.data.number);
        setItemsCountPerPage(res.data.size);
        isLoading(false);
      });
    }
  }, [categoryId, prevSearch, searchProductInput, pageNumber, refreshPage]);

  if (editProductModel.showModel) {
    return (
      <EditProduct
        setEditProductModel={setEditProductModel}
        editProductModel={editProductModel}
        setRefreshPage={setRefreshPage}
      />
    );
  }

  if (createModel) {
    return (
      <CreateProduct
        setCreateModel={setCreateModel}
        createModel={createModel}
        setRefreshPage={setRefreshPage}
      />
    );
  }

  if (moreProductInfoModel.showModel) {
    return (
      <MoreProductInfo
        setMoreProductInfoModel={setMoreProductInfoModel}
        moreProductInfoModel={moreProductInfoModel}
      />
    );
  }

  if (loading) {
    return <Spinner3D />;
  }

  if (totalElements === 0) {
    return (
      <div>
        <NotFoundErrorPage
          textHeader="There are no Products!"
          textButton="Please add a Product!"
          onClick={() => setCreateModel(!createModel)}
        />
        <NotFoundErrorPage
          textHeader="Or"
          textButton="Go back to Products!"
          onClick={refreshPageIfNoOrderFound}
        />
      </div>
    );
  }

  return (
    <div className="grid grid-cols-7 gap-5 px-16">
      <ProductsActionAdmin
        createModel={createModel}
        setCreateModel={setCreateModel}
        searchProductInput={searchProductInput}
        setSearchProductInput={setSearchProductInput}
        setCategoryId={setCategoryId}
      />
      <div className="col-span-6">
        <div className="flex justify-between">
          <div>
            Total products: {totalElements}
            <div>
              {showErrorSearchMin.show && (
                <Badge3D variant="warning" text={showErrorSearchMin.message} />
              )}
            </div>
          </div>
          <Pagination3D
            currentPage={currentPage}
            totalElements={totalElements}
            itemsCountPerPage={itemsCountPerPage}
            handlePageChange={handlePageChange}
          />
        </div>
        <ProductTableAdminView
          products={products}
          setRefreshPage={setRefreshPage}
          itemsCountPerPage={itemsCountPerPage}
          pageNumber={pageNumber}
          editProductModel={editProductModel}
          setEditProductModel={setEditProductModel}
          moreProductInfoModel={moreProductInfoModel}
          setMoreProductInfoModel={setMoreProductInfoModel}
        />
      </div>
    </div>
  );
};

export default ProductsTableAdmin;

const ProductTableAdminView = ({
  products,
  setRefreshPage,
  itemsCountPerPage,
  pageNumber,
  editProductModel,
  setEditProductModel,
  moreProductInfoModel,
  setMoreProductInfoModel,
}) => {
  return (
    <table className="table-fixed my-3">
      <thead>
        <tr className="blue text-center">
          <th className="border-2 px-2 py-3 border-gray-500 w-1/12">#</th>
          <th className="border-2 px-2 py-3 border-gray-500 w-5/12">NAME</th>
          <th className="border-2 px-2 py-3 border-gray-500 w-1/12">
            CATEGORY
          </th>
          <th className="border-2 px-2 py-3 border-gray-500 w-1/12">PRICE</th>
          <th className="border-2 px-2 py-3 border-gray-500 w-1/12">
            <Popup3D text="OUT OF S" popupText="OUT OF STOCK" />
          </th>
          <th className="border-2 px-2 py-3 border-gray-500 w-1/12">
            <Popup3D text="OSA" popupText="Only available on shop" />
          </th>
          <th className="border-2 px-2 py-3 border-gray-500 w-1/12">
            <Popup3D text="REC" popupText="RECOMMENDED" />
          </th>
          <th className="border-2 px-2 py-3 border-gray-500 w-1/12">EDIT</th>
          <th className="border-2 px-2 py-3 border-gray-500 w-1/12">DELETE</th>
        </tr>
      </thead>
      <tbody>
        {products.map((product, i) => (
          <ProductTableAdminContent
            key={i}
            product={product}
            i={i}
            setRefreshPage={setRefreshPage}
            itemsCountPerPage={itemsCountPerPage}
            pageNumber={pageNumber}
            editProductModel={editProductModel}
            setEditProductModel={setEditProductModel}
            moreProductInfoModel={moreProductInfoModel}
            setMoreProductInfoModel={setMoreProductInfoModel}
          />
        ))}
        ;
      </tbody>
    </table>
  );
};

const ProductTableAdminContent = ({
  product,
  i,
  setRefreshPage,
  itemsCountPerPage,
  pageNumber,
  editProductModel,
  setEditProductModel,
  moreProductInfoModel,
  setMoreProductInfoModel,
}) => {
  const handleMakeOutOfStock = (productId) => {
    makeProductOutOfStockOrNot(productId)
      .then(() => {
        setRefreshPage(Math.random());
      })
      .catch((err) => {
        errorAlert3D(err);
      });
  };

  const handleMakeOnlyShopAvailable = (productId) => {
    makeProductOnlyShopAvailable(productId)
      .then(() => {
        setRefreshPage(Math.random());
      })
      .catch((err) => {
        errorAlert3D(err);
      });
  };

  const handleMakeRecommended = (productId) => {
    makeProductRecommendedOrNot(productId)
      .then(() => {
        setRefreshPage(Math.random());
      })
      .catch((err) => {
        errorAlert3D(err);
      });
  };

  const handleRowClick = (product) => {
    setMoreProductInfoModel({
      showModel: !moreProductInfoModel.showModel,
      product,
    });
  };

  const handleDeleteOneProduct = (productId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProduct(productId)
          .then(() => {
            setRefreshPage(Math.random());
            Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
          })
          .catch((err) => {
            errorAlert3D(err);
          });
      }
    });
  };

  return (
    <tr key={i}>
      <td
        onClick={() => handleRowClick(product)}
        className="text-center cursor-pointer border-2 px-3 py-3 border-gray-500"
      >
        {i + 1 + itemsCountPerPage * pageNumber}
      </td>
      <td className="outline-none border-2 px-3 py-3 border-gray-500">
        {product.name}
      </td>
      <td
        className="cursor-pointer text-center border-2 px-3 py-3 border-gray-500"
        onClick={() => handleRowClick(product)}
      >
        {product.category.categoryName}
      </td>
      <td
        className="text-center cursor-pointer border-2 px-3 py-3 border-gray-500"
        onClick={() => handleRowClick(product)}
      >
        {product.price}
      </td>
      <td className="blue text-center border-2 px-3 py-3 border-gray-500">
        <input
          type="checkbox"
          checked={product.outOfStock}
          onChange={(e) => handleMakeOutOfStock(e.target.name)}
          name={product.id}
          className="m-1"
        />
      </td>
      <td className="blue text-center border-2 px-3 py-3 border-gray-500">
        <input
          type="checkbox"
          checked={product.onlyShopAvailable}
          onChange={(e) => handleMakeOnlyShopAvailable(e.target.name)}
          name={product.id}
          className="m-1"
        />
      </td>
      <td className="blue text-center border-2 px-3 py-3 border-gray-500">
        <input
          type="checkbox"
          checked={product.recommended}
          onChange={(e) => handleMakeRecommended(e.target.name)}
          name={product.id}
          className="m-1"
        />
      </td>
      <td className="border-2 px-3 py-3 border-gray-500">
        <div className="flex justify-center">
          <Button3D
            variant="edit-table"
            onClick={() =>
              setEditProductModel({
                showModel: !editProductModel.showModel,
                product,
              })
            }
          />
        </div>
      </td>
      <td className="border-2 px-3 py-3 border-gray-500">
        <div className="flex justify-center">
          <Button3D
            variant="delete-table"
            onClick={() => handleDeleteOneProduct(product.id)}
          />
        </div>
      </td>
    </tr>
  );
};

const ProductsActionAdmin = ({
  createModel,
  setCreateModel,
  searchProductInput,
  setSearchProductInput,
  setCategoryId,
}) => {
  return (
    <div>
      <div className="py-10">
        <Button3D
          variant="admin-main"
          text="Create"
          onClick={() => setCreateModel(!createModel)}
        />
      </div>
      <hr />
      <div className="py-10">
        <div className="my-3">
          <label className="text-lg">Search:</label>
        </div>
        <div className="mb-5">
          <input
            className="shadow appearance-none border rounded w-48 text-white-700 text-lg py-3 px-4 h-10"
            style={{
              backgroundColor: 'var(--color-background)',
              borderRadius: 150,
            }}
            id="search"
            type="text"
            placeholder="Product name"
            onChange={(e) => setSearchProductInput(e.target.value)}
            value={searchProductInput}
          />
        </div>
      </div>
      <hr />
      <div className="py-10">
        <CategoryDropDownList
          currentCategoryName="Select Category"
          setCategoryId={setCategoryId}
        />
      </div>
    </div>
  );
};
