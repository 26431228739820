import React, { useContext, useEffect, useState } from 'react';
import './OrderPage.css';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { OrderContext } from '../../services/context/OrderContext';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import OrderConfirmationModal from './OrderConfirmationModal';
import Spinner3D from '../../common/spinner/Spinner3D';
import Button3D from '../../common/layout/Button3D';
import OrderSubmitCompleteModal from './OrderSubmitModal';
import { CurrencyContext } from '../../services/context/CurrencyContext';
import TotalPrice from './TotalPrice';
import Badge3D from '../../common/layout/Badge3D';

const customStyles = {
  control: (base) => ({
    ...base,
    background: 'transparent',
  }),
  input: (base) => ({
    ...base,
    color: 'var(--color-text)',
  }),
  singleValue: (base) => ({
    ...base,
    color: 'var(--color-text)',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#1f2833',
      color: 'var(--color-text)',
      cursor: isDisabled ? 'not-allowed' : 'default',
    };
  },
};

const OrderPage = () => {
  const { t } = useTranslation('order_page');

  const options = [
    { value: 'Baghdad', label: t('baghdad') },
    { value: 'Baghdad Outskirts', label: t('baghdad-outskirts') },
    { value: 'Basra', label: t('basra') },
    { value: 'Najaf', label: t('najaf') },
    { value: 'Maysan', label: t('maysan') },
    { value: 'Dhi Qar', label: t('dhiqar') },
    { value: 'Al-Qādisiyyah', label: t('qdisiyyah') },
    { value: 'Muthanna', label: t('muthanna') },
    { value: 'Wasit', label: t('wasit') },
    { value: 'Karbala', label: t('karbala') },
    { value: 'Babil', label: t('babil') },
    { value: 'Al Anbar', label: t('anbar') },
    { value: 'Diyala', label: t('diyala') },
    { value: 'Saladin', label: t('saladin') },
    { value: 'Erbil', label: t('erbil') },
    { value: 'Kirkuk', label: t('kirkuk') },
    { value: 'Sulaymaniyah', label: t('sulaymaniyah') },
    { value: 'Halabja', label: t('halabja') },
    { value: 'Nineveh', label: t('nineveh') },
    { value: 'Duhok', label: t('duhok') },
  ];

  const order = useContext(OrderContext);
  const orderedProducts = [];

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [
    openConfirmationCompleteModal,
    setOpenConfirmationCompleteModal,
  ] = useState(false);
  const [
    confirmationMessageCompleteModal,
    setConfirmationMessageCompleteModal,
  ] = useState('');

  const showConfirmationModal = (setSubmitting) => {
    setOpenConfirmationModal(!openConfirmationModal);
    setSubmitting(false);
  };

  order.products.forEach((product) =>
    orderedProducts.push({
      productId: product.id,
      productCount: product.count,
    })
  );

  const [totalPrice, setTotalPrice] = useState(0);
  const currency = useContext(CurrencyContext);

  useEffect(() => {
    if (localStorage.getItem('money') === 'dollar') {
      setTotalPrice(order.totalPrice);
    } else {
      setTotalPrice(order.totalPrice * currency.currencyPrice);
    }
  }, [currency, order]);

  const initialValues = {
    name: '',
    city: '',
    companyName: '',
    district: '',
    district2: '',
    mobileNumber: '',
    email: '',
    notes: '',
    orderedProducts: orderedProducts,
  };

  const validationSchema = yup.object({
    name: yup.string().required(t('requirements01')),
    city: yup.string().required(t('requirements02')),
    district: yup.string().required(t('requirements03')),
    mobileNumber: yup
      .string()
      .matches(/^[0-9]{11}$/, t('requirements04'))
      .required(t('requirements05')),
    email: yup.string().email(t('requirements06')),
    orderedProducts: yup.string().required(t('requirements07')),
  });

  return (
    <div className="md:h-80 m-auto md:pt-16 md:px-40 text-center md:rounded-lg p-4">
      <div className=" py-20 md:py-0">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={() => {
            setOpenConfirmationModal(true);
          }}
        >
          {({
            values,
            isSubmitting,
            errors,
            touched,
            handleBlur,
            setFieldValue,
            setSubmitting,
            resetForm,
          }) => (
            <div>
              {
                <OrderConfirmationModal
                  order={order}
                  t={t}
                  openConfirmationModal={openConfirmationModal}
                  closeConfirmationModal={() =>
                    showConfirmationModal(setSubmitting)
                  }
                  orderForm={values}
                  setSubmitting={setSubmitting}
                  resetForm={resetForm}
                  setConfirmedCompleteOrderModal={
                    setOpenConfirmationCompleteModal
                  }
                  setConfirmationMessageCompleteModal={
                    setConfirmationMessageCompleteModal
                  }
                />
              }
              {
                <OrderSubmitCompleteModal
                  openConfirmedCompleteOrder={openConfirmationCompleteModal}
                  setConfirmedCompleteOrderModal={
                    setOpenConfirmationCompleteModal
                  }
                  message={confirmationMessageCompleteModal}
                />
              }
              <Form action="/">
                <div className="ptb-100">
                  <div className="md:grid grid-cols-2 justify-items-center gap-10">
                    {/* Order form */}
                    <div className="w-full">
                      <h3 className="blue">{t('user-info')}</h3>

                      <div style={{ margin: '1rem 0' }}>
                        <label htmlFor="city">{t('governorate')}</label>
                        <Select
                          styles={customStyles}
                          defaultValue={{
                            label: 'choose city',
                          }}
                          id="city"
                          name="city"
                          options={options}
                          onBlur={handleBlur}
                          onChange={(option) =>
                            setFieldValue('city', option.value)
                          }
                          value={{ label: values.city }}
                        />
                        <div className="p-1">
                          {errors.city && touched.city && (
                            <Badge3D variant="warning" text={errors.city} />
                          )}
                        </div>
                      </div>

                      <div style={{ margin: '1rem 0' }}>
                        <label>
                          {t('customer-name')}
                          <span className="required"> *</span>
                        </label>
                        <Field
                          className="mr-3 px-3 border rounded h-10 w-full"
                          style={{
                            backgroundColor: 'var(--color-background)',
                            borderRadius: 150,
                          }}
                          name="name"
                          as="input"
                        />
                        <div className="p-1">
                          {errors.name && touched.name && (
                            <Badge3D variant="warning" text={errors.name} />
                          )}
                        </div>
                      </div>
                      <div style={{ margin: '1rem 0' }}>
                        <label>{t('company')}</label>
                        <Field
                          className="mr-3 px-3 border rounded h-10 w-full"
                          style={{
                            backgroundColor: 'var(--color-background)',
                            borderRadius: 150,
                          }}
                          type="text"
                          placeholder={t('company-owner')}
                          name="companyName"
                          as="input"
                        />
                      </div>
                      <div style={{ margin: '1rem 0' }}>
                        <label>
                          {t('region')} <span className="required"> *</span>
                        </label>
                        <Field
                          className="mr-3 px-3 border rounded h-10 w-full"
                          style={{
                            backgroundColor: 'var(--color-background)',
                            borderRadius: 150,
                          }}
                          type="text"
                          name="district"
                          placeholder=""
                          as="input"
                        />
                        <div className="p-1">
                          {errors.district && touched.district && (
                            <Badge3D variant="warning" text={errors.district} />
                          )}
                        </div>
                      </div>
                      <div style={{ margin: '1rem 0' }}>
                        <label>{t('point')}</label>
                        <Field
                          className="mr-3 px-3 border rounded h-10 w-full"
                          style={{
                            backgroundColor: 'var(--color-background)',
                            borderRadius: 150,
                          }}
                          type="text"
                          name="district2"
                          placeholder=""
                          as="input"
                        />
                      </div>
                      <div style={{ margin: '1rem 0' }}>
                        <label>{t('e-mail')}</label>
                        <Field
                          className="mr-3 px-3 border rounded h-10 w-full"
                          style={{
                            backgroundColor: 'var(--color-background)',
                            borderRadius: 150,
                          }}
                          type="text"
                          name="email"
                          placeholder=""
                          as="input"
                        />
                        <div className="p-1">
                          {errors.email && touched.email && (
                            <Badge3D variant="warning" text={errors.email} />
                          )}
                        </div>
                      </div>

                      <div style={{ margin: '1rem 0' }}>
                        <label>
                          {t('phone')}
                          <span className="required"> *</span>
                        </label>
                        <Field
                          className="mr-3 px-3 border rounded h-10 w-full"
                          style={{
                            backgroundColor: 'var(--color-background)',
                            borderRadius: 150,
                          }}
                          type="text"
                          name="mobileNumber"
                          placeholder={'07901000000'}
                          as="input"
                        />
                        <div className="p-1">
                          {errors.mobileNumber && touched.mobileNumber && (
                            <Badge3D
                              variant="warning"
                              text={errors.mobileNumber}
                            />
                          )}
                        </div>
                      </div>

                      <div style={{ margin: '1rem 0' }}>
                        <label>{t('notes')}</label>
                        <Field
                          className="mr-3 px-3 border rounded h-10 w-full"
                          style={{
                            backgroundColor: 'var(--color-background)',
                            borderRadius: 150,
                          }}
                          type="textarea"
                          cols="30"
                          rows="10"
                          name="notes"
                          placeholder={t('notes about')}
                          as="input"
                        />
                      </div>
                    </div>
                    {/* Order information */}
                    <div className="mt-5 w-full">
                      <h4 className="py-1 blue">{t('ordered items')}</h4>
                      <table className="mt-3 w-full table-auto">
                        <thead>
                          <tr>
                            <th className="border-2 px-2 py-3 border-gray-500">
                              {t('item')}
                            </th>
                            <th className="border-2 px-2 py-3 border-gray-500">
                              {t('quantity')}
                            </th>
                            <th className="border-2 px-2 py-3 border-gray-500">
                              {t('price')}
                            </th>
                          </tr>
                        </thead>
                        {order.products.map((item, i) => (
                          <OrderItem key={i} item={item} />
                        ))}
                        <tfoot>
                          <tr>
                            <td
                              className="border-2 px-2 py-3 border-gray-500"
                              colSpan="2"
                            >
                              {values.city === 'Baghdad'
                                ? '5000 IQD'
                                : values.city === 'Baghdad Outskirts'
                                ? '8000 IQD'
                                : values.city === ''
                                ? ''
                                : '10000 IQD'}
                            </td>
                            <td className="border-2 px-2 py-3 border-gray-500">
                              {t('transport-price')}
                            </td>
                          </tr>
                          <tr>
                            <td
                              className="border-2 px-2 py-3 border-gray-500"
                              colSpan="2"
                            >
                              <TotalPrice
                                totalPrice={totalPrice}
                                city={values.city}
                              />
                            </td>
                            <td className="border-2 px-2 py-3 border-gray-500">
                              {t('total')}
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                      <br />
                      <br />
                      <br />
                      <div className="blue">
                        <h5>{t('paiement')}</h5>
                      </div>
                      <div>
                        <div>
                          <p className="p-1">{t('note1')}</p>
                          <p className="p-1">{t('note2')}</p>
                          <p className="p-1">{t('note3')}</p>
                          <p className="p-1">
                            {t('note4')}
                            <a href="/follow-order">
                              <br />
                              <div className="mt-3 text-white text-xl">
                                {t('press')}
                              </div>
                            </a>
                          </p>
                          <p className="p-1">{t('note5')}</p>
                        </div>
                      </div>
                      <div className="pt-10">
                        <Field
                          className="bg-blue border-2 border-white rounded-md text-white w-56 h-12"
                          text={t('order now')}
                          type="submit"
                          disabled={isSubmitting}
                          placeholder=""
                          as={Button3D}
                        />
                        {isSubmitting ? <Spinner3D /> : null}
                        <div className="p-1">
                          {errors.orderedProducts &&
                            touched.orderedProducts && (
                              <Badge3D
                                variant="warning"
                                text={errors.orderedProducts}
                              />
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default OrderPage;

const OrderItem = ({ item }) => {
  const [price, setPrice] = useState(0);
  const currency = useContext(CurrencyContext);

  useEffect(() => {
    if (localStorage.getItem('money') === 'dollar') {
      setPrice(item.price);
    } else {
      setPrice(item.price * currency.currencyPrice);
    }
  }, [currency, item]);

  return (
    <tbody>
      <tr>
        <td className="border-2 px-2 py-3 border-gray-500">{item.name}</td>
        <td className="border-2 px-2 py-3 border-gray-500">{item.count}</td>
        <td className="border-2 px-2 py-3 border-gray-500">
          <div className="flex justify-center">
            {(price * item.count).toLocaleString()}
            <div className="ml-1">
              {localStorage.getItem('money') === 'dollar' ? '$' : 'IQD'}
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  );
};
