import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import GoBackButton from './GoBackButton';
import ProductImages from './ProductImages';
import Badge3D from '../../../../common/layout/Badge3D';
import PriceAndAddToCartView from './PriceAndAddToCartView';
import AddProductToCartModal from '../../common/AddProductToCartModal';
import ReactHtmlParser from 'react-html-parser';

const ProductDetailsView = ({
  product,
  price,
  assemblePrice,
  currentPage,
  history,
  searchBack,
  searchedProductName,
}) => {
  const { t } = useTranslation('common');
  let [counter, setCounter] = useState(1);
  const [showModal, setShowModal] = useState(false);

  const showAddToCartConfirmation = () => {
    setShowModal(!showModal);
  };

  const updateProductCount = (newCount) => {
    setCounter(newCount);
  };

  return (
    <section className="overflow-hidden">
      <div className="container px-5 py-24 mx-auto">
        <div className="lg:w-4/5 mx-auto flex flex-wrap">
          <GoBackButton
            currentPage={currentPage}
            history={history}
            product={product}
            searchBack={searchBack}
            searchedProductName={searchedProductName}
          />
          <div className="grid md:grid-cols-2 grid-cols-1 mt-5">
            <div className="col-span-1 w-full h-auto h-64 object-cover object-center rounded">
              <ProductImages product={product} />
            </div>
            <div className="col-span-1 w-full md:pl-12 md:py-6 md:mt-6 mt-3">
              <h2 className="text-md title-font tracking-widest">
                {product.category.categoryName}
              </h2>
              <h1 className="pt-1 text-3xl title-font font-medium mb-1">
                {product.name}
              </h1>
              <div className="remove-all-styles">
                {product.description && ReactHtmlParser(product.description)}
              </div>
              {product.comingSoon ? (
                <ComingSoonView t={t} />
              ) : product.outOfStock ? (
                <OutOfStockView t={t} />
              ) : product.onlyShopAvailable ? (
                <OnlyInShopsView t={t} />
              ) : (
                <PriceAndAddToCartView
                  product={product}
                  price={price}
                  assemblePrice={assemblePrice}
                  counter={counter}
                  showAddToCartConfirmation={showAddToCartConfirmation}
                  updateProductCount={updateProductCount}
                />
              )}
              <div>
                {!product.comingSoon &&
                  !product.outOfStock &&
                  !product.onlyShopAvailable &&
                  product.sale && <OnSaleView t={t} />}
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        <AddProductToCartModal
          open={showModal}
          close={showAddToCartConfirmation}
        />
      }
    </section>
  );
};

export default ProductDetailsView;

const OnlyInShopsView = ({ t }) => {
  return (
    <div>
      <div className="border-b-2 border-gray-100 my-5" />
      <div className="flex justify-center">
        <Badge3D text={t('only-available-on-shop')} variant="very-red" />
      </div>
    </div>
  );
};

const OutOfStockView = ({ t }) => {
  return (
    <div>
      <div className="border-b-2 border-gray-100 my-5" />
      <div className="flex justify-center">
        <Badge3D text={t('out-of-stock')} variant="red" />
      </div>
    </div>
  );
};

const ComingSoonView = ({ t }) => {
  return (
    <div>
      <div className="border-b-2 border-gray-100 my-5" />
      <div className="flex justify-center">
        <Badge3D text={t('coming-soon')} variant="red" />
      </div>
    </div>
  );
};

const OnSaleView = ({ t }) => {
  return (
    <div>
      <div className="border-b-2 border-gray-100 my-5" />
      <div className="flex justify-center">
        <Badge3D text={t('sale')} variant="yellow" />
      </div>
    </div>
  );
};
