import React, { useEffect, useState } from 'react';
import './HomePageSlider.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getHomePageImages } from '../../services/client';
import { Link as RouterLink } from 'react-router-dom';
import SlickArrowLeft from '../layout/SlickArrowLeft';
import SlickArrowRight from '../layout/SlickArrowRight';

function HomePageSlider() {
  const [productHomePageImages, setProductHomePageImages] = useState([]);

  useEffect(() => {
    getHomePageImages().then((res) => {
      setProductHomePageImages(res.data);
    });
  }, []);

  const settings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 4000,
    autoplaySpeed: 3000,
    autoplay: true,
    infinite: true,
    nextArrow: <SlickArrowLeft />,
    prevArrow: <SlickArrowRight />,
  };

  return (
    <div>
      <div className="flex justify-center p-5">
        <Slider {...settings} style={{ width: '80%', height: '100%' }}>
          {productHomePageImages.reverse().map((product, i) => (
            <RouterLink
              to={{
                pathname: `/product/${product.productId}`,
                state: { productDetails: null, currentPage: -1 },
              }}
              className="d-block h-slider"
              key={i}
            >
              <img
                src={product.productHomeScreenPicLocation}
                alt={product.productId}
              />
            </RouterLink>
          ))}
        </Slider>
      </div>
      {/*
      {productHomePageImages[0] && productHomePageImages[1] && (
        <div className="flex justify-center">
          <img
            style={{ width: '35%', alignSelf: 'flex-start' }}
            className="w-1/2 mr-3"
            src={productHomePageImages[0].productHomeScreenPicLocation}
            alt={productHomePageImages[0].productId}
          />
          <img
            style={{ width: '35%', alignSelf: 'flex-start' }}
            className="w-1/2"
            src={productHomePageImages[1].productHomeScreenPicLocation}
            alt={productHomePageImages[1].productId}
          />
        </div>
      )}
      */}
    </div>
  );
}

export default HomePageSlider;
