import React, { useContext } from 'react';
import Counter3D from '../../../../common/counter/Counter3D';
import Button3D from '../../../../common/layout/Button3D';
import { OrderContext } from '../../../../services/context/OrderContext';
import { useTranslation } from 'react-i18next';
import Badge3D from '../../../../common/layout/Badge3D';
import { addProductToCart } from '../../common/addProductToCart';

const PriceAndAddToCartView = ({
  product,
  price,
  assemblePrice,
  counter,
  showAddToCartConfirmation,
  updateProductCount,
}) => {
  const { t } = useTranslation('common');
  return (
    <div>
      <div className="flex justify-center md:justify-end mt-6">
        {t('price')}
      </div>
      <div className="flex justify-center md:justify-end title-font font-medium text-2xl">
        <span>{(price * counter).toLocaleString()}</span>
        <div className="ml-2">
          {localStorage.getItem('money') === 'dollar' ? '$' : 'IQD'}
        </div>
      </div>

      {assemblePrice > 0 && (
        <div>
          <div className="flex justify-center md:justify-end my-2">
            {t('price-assemble')}
          </div>
          <div className="flex justify-center md:justify-end title-font font-medium text-2xl">
            <span>{assemblePrice.toLocaleString()}</span>
            <div className="ml-2">
              {localStorage.getItem('money') === 'dollar' ? '$' : 'IQD'}
            </div>
          </div>
        </div>
      )}

      <div className="flex items-center p1-5 border-b-2 border-gray-100 mb-5" />

      <div className="flex justify-evenly md:mt-1 mt-4">
        <Counter3D count={counter} onCountChange={updateProductCount} />
        <Button3D
          text={t('add-to-basket')}
          onClick={() => {
            addProductToCart(product, counter, showAddToCartConfirmation);
          }}
        />
      </div>

      <div className="p1-5 flex justify-center pt-5">
        <ShowHowManyAlreadyInShoppingCart product={product} />
      </div>
    </div>
  );
};

export default PriceAndAddToCartView;

const ShowHowManyAlreadyInShoppingCart = ({ product }) => {
  const order = useContext(OrderContext);
  const { t } = useTranslation('common');

  const showHowManyProductsInShoppingCartIfAny = (productId) => {
    if (
      order.products.length !== 0 &&
      order.products.find((prd) => prd.id === productId)
    ) {
      const productFromOrderList = order.products.find(
        (prd) => prd.id === productId
      );
      return productFromOrderList.count;
    }
  };

  return (
    <div>
      {showHowManyProductsInShoppingCartIfAny(product.id) && (
        <Badge3D
          variant="blue"
          text={
            <p className="text-right w-64 md:w-full">
              {t('you-have')}
              <b className="mx-2 text-xl text-blue-900">
                {showHowManyProductsInShoppingCartIfAny(product.id)}
              </b>
              {t('products-in-cart')}
            </p>
          }
        />
      )}
    </div>
  );
};
