import React from 'react';

const Badge3D = ({ text, variant, show }) => {
  switch (variant) {
    case 'warning':
      return <WarningBadge text={text} />;
    case 'very-red':
      return <VeryRedBadge text={text} />;
    case 'red':
      return <RedBadge text={text} />;
    case 'blue':
      return <BlueBadge text={text} />;
    case 'yellow':
      return <YellowBadge text={text} />;
    case 'danger-order-state':
      return <DangerOrderStateBadge text={text} show={show} />;
    default:
      return null;
  }
};

export default Badge3D;

const WarningBadge = ({ text }) => {
  return (
    <div>
      <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white bg-yellow-600 rounded-full">
        {text}
      </span>
    </div>
  );
};

const RedBadge = ({ text }) => {
  return (
    <div>
      <span className="px-8 inline-flex rounded-md items-center justify-center py-4 mr-2 text-m font-bold leading-none bg-red-500">
        {text}
      </span>
    </div>
  );
};

const VeryRedBadge = ({ text }) => {
  return (
    <div>
      <span className="px-8 inline-flex rounded-md items-center justify-center py-4 mr-2 text-m font-bold leading-none bg-red-900">
        {text}
      </span>
    </div>
  );
};

const BlueBadge = ({ text }) => {
  return (
    <div>
      <span className="px-8 inline-flex rounded-md  items-center justify-center py-4 mr-2 text-m font-bold leading-none bg-blue-700">
        {text}
      </span>
    </div>
  );
};

const DangerOrderStateBadge = ({ text, show }) => {
  return (
    <div>
      {show && (
        <span className="inline-flex items-center justify-center px-4 py-2 mr-2 text-m font-bold leading-none text-white bg-red-600 rounded-full">
          {text}
        </span>
      )}
    </div>
  );
};

const YellowBadge = ({ text }) => {
  return (
    <div>
      <span className="px-8 inline-flex rounded-md items-center justify-center py-4 mr-2 text-m font-bold leading-none bg-yellow-600">
        {text}
      </span>
    </div>
  );
};
