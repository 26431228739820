import React, { useState } from 'react';
import Cross from '@material-ui/icons/Close';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { addProduct } from '../../../../services/client';
import { CategoryDropDownList } from './CategoryDropDownList';
import { UploadImage } from '../../../../common/formik/UploadImage';
import { FormikForm } from '../../../../common/formik/FormikForm';
import { errorAlert3D } from '../../../../common/error3D';
import { closeWindowAndRefreshComponent } from '../../../../common/common3D';
import Badge3D from '../../../../common/layout/Badge3D';
import { Editor } from '@tinymce/tinymce-react';

const initialValues = {
  productName: '',
  productPrice: '',
  priceAssemble: 0,
  categoryId: '',
  productImages: [],
  description: '',
  picLocation: '',
};

const validationSchema = yup.object({
  productName: yup
    .string()
    .test(
      'len',
      'Please add a name with more than 5 characters',
      (productName) => {
        if (productName) {
          return productName.toString().length >= 5;
        }
      }
    ),
  productPrice: yup
    .number()
    .typeError('You must specify a number')
    .positive('The price must be greater than zero')
    .required('Please add a price to the product!'),
  priceAssemble: yup
    .number()
    .typeError('You must specify a number')
    .required('Please add an assemble price to the product, or zero!'),
  categoryId: yup.string().required('Please add a category to the product!'),
  picLocation: yup.string().required('Please add a main image to the product!'),
  productImages: yup.string().required('Please add images to the product!'),
});

const CreateProduct = ({ setCreateModel, createModel, setRefreshPage }) => {
  const [recommended, setRecommended] = useState(false);
  const [comingSoon, setComingSoon] = useState(false);
  const [sale, setSale] = useState(false);
  const [outOfStock, setOutOfStock] = useState(false);
  const [onlyShopAvailable, setOnlyShopAvailable] = useState(false);
  const [description, setDescription] = useState('');

  const handleSwitchRecommended = (e) => {
    setRecommended(e.target.checked);
  };

  const handleSwitchSale = (e) => {
    setSale(e.target.checked);
  };

  const handleSwitchOutOfStock = (e) => {
    setOutOfStock(e.target.checked);
  };

  const handleSwitchOnlyShopAvailable = (e) => {
    setOnlyShopAvailable(e.target.checked);
  };

  const handleComingSoon = (e) => {
    setComingSoon(e.target.checked);
  };

  const handleEditorChange = (content, editor) => {
    setDescription(content);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(product, { setSubmitting, resetForm }) => {
        const formData = new FormData();
        product.productImages.forEach((item) => {
          formData.append('productImages', item);
        });
        formData.append('picLocation', product.picLocation[0]);
        if (product.homeScreenPicLocation) {
          formData.append(
            'homeScreenPicLocation',
            product.homeScreenPicLocation[0]
          );
        } else {
          formData.append('homeScreenPicLocation', new Blob());
        }
        formData.append('productName', product.productName);
        formData.append('productPrice', product.productPrice);
        formData.append('priceAssemble', product.priceAssemble);
        formData.append('categoryId', product.categoryId);
        formData.append('description', description);
        formData.append('recommended', recommended);
        formData.append('sale', sale);
        formData.append('comingSoon', comingSoon);
        formData.append('outOfStock', outOfStock);
        formData.append('onlyShopAvailable', onlyShopAvailable);
        addProduct(formData)
          .then((res) => {
            resetForm({});
            setSubmitting(false);
            closeWindowAndRefreshComponent(
              setCreateModel,
              createModel,
              setRefreshPage
            );
          })
          .catch((err) => {
            errorAlert3D(err);
            setSubmitting(false);
          });
      }}
    >
      {({ submitForm, isSubmitting, errors, touched, setFieldValue }) => (
        <Form className="container items-center z-40">
          <div
            className="text-center md:rounded-lg p-4 md:relative"
            style={{ backgroundColor: 'var(--color-secondary)' }}
          >
            <div className="flex justify-end text-xl">
              <Cross
                className="cursor-pointer"
                onClick={() => setCreateModel(!createModel)}
                width="20"
                height="20"
                alt="exit"
              />
            </div>
            <div className="px-56 text-xl">
              <div>
                <div className="flex justify-center text-3xl">
                  Create new Product
                </div>
                <div className="pt-5">
                  <FormikForm
                    as={'input'}
                    name="productName"
                    label="Product Name: "
                    errors={errors.productName}
                    touched={touched.productName}
                  />
                  <div className="py-1" />
                  <FormikForm
                    as={'input'}
                    name="productPrice"
                    label="Product Price: "
                    errors={errors.productPrice}
                    touched={touched.productPrice}
                  />
                  <div className="py-1" />
                  <FormikForm
                    as={'input'}
                    name="priceAssemble"
                    label="Product Assemble Price: "
                    errors={errors.priceAssemble}
                    touched={touched.priceAssemble}
                  />
                  <div className="py-1" />
                  <div className="flex justify-between model-p blue">
                    <label>Product Category:</label>
                    <Field
                      name="categoryId"
                      currentCategoryName="Choose Category"
                      component={CategoryDropDownList}
                    />
                  </div>
                  <div className="pt-2 flex justify-end">
                    {errors.categoryId && touched.categoryId && (
                      <Badge3D variant="warning" text={errors.categoryId} />
                    )}
                  </div>
                  <div className="py-1" />
                  <div className="flex justify-between model-p blue">
                    <label>Is this product on Sale?</label>
                    <input
                      name="sale"
                      type="checkbox"
                      checked={sale}
                      onChange={handleSwitchSale}
                      className="m-1"
                    />
                  </div>
                  <div className="py-1" />
                  <div className="flex justify-between model-p blue">
                    <label>Is this product available only on shop?</label>
                    <input
                      name="onlyShopAvailable"
                      type="checkbox"
                      checked={onlyShopAvailable}
                      onChange={handleSwitchOnlyShopAvailable}
                      className="m-1"
                    />
                  </div>
                  <div className="py-1" />
                  <div className="flex justify-between model-p blue">
                    <label>Is this product Out Of Stock?</label>
                    <input
                      name="outOfStock"
                      type="checkbox"
                      checked={outOfStock}
                      onChange={handleSwitchOutOfStock}
                      className="m-1"
                    />
                  </div>
                  <div className="py-1" />
                  <div className="flex justify-between model-p blue">
                    <label>Is this product recommended?</label>
                    <input
                      name="recommended"
                      type="checkbox"
                      checked={recommended}
                      onChange={handleSwitchRecommended}
                      className="m-1"
                    />
                  </div>
                  <div className="py-1" />
                  <div className="flex justify-between model-p blue">
                    <label>Will this product be available soon?</label>
                    <input
                      name="comingSoon"
                      type="checkbox"
                      checked={comingSoon}
                      onChange={handleComingSoon}
                      className="m-1"
                    />
                  </div>
                  <div className="py-1" />
                  <div>
                    <div className="flex justify-between model-p blue">
                      <label>Product Main Image:</label>
                      <UploadImage
                        maxFiles={1}
                        popup="Only 1 image is allowed"
                        name="picLocation"
                        buttonText="Upload Main image"
                        setFieldValue={setFieldValue}
                      />
                    </div>
                    <div className="pt-2 flex justify-end">
                      {errors.picLocation && touched.picLocation && (
                        <Badge3D variant="warning" text={errors.picLocation} />
                      )}
                    </div>
                  </div>
                  <div className="py-1" />
                  <div>
                    <div className="flex justify-between model-p blue">
                      <label>Product Image:</label>
                      <UploadImage
                        maxFiles={5}
                        popup="Only 5 images are allowed"
                        name="productImages"
                        buttonText="Upload images"
                        setFieldValue={setFieldValue}
                      />
                    </div>
                    <div className="pt-2 flex justify-end">
                      {errors.productImages && touched.productImages && (
                        <Badge3D
                          variant="warning"
                          text={errors.productImages}
                        />
                      )}
                    </div>
                  </div>
                  <div className="py-1" />
                  <div>
                    <div className="flex justify-between model-p blue">
                      <label>Add to Home Screen Pictures?:</label>
                      <UploadImage
                        maxFiles={1}
                        popup="Only 1 image is allowed"
                        name="homeScreenPicLocation"
                        buttonText="Upload HS image"
                        setFieldValue={setFieldValue}
                      />
                    </div>
                  </div>
                  <div className="py-1" />
                  <label className="pb-1">Product Description:</label>
                  <Editor
                    initialValue={description}
                    apiKey="3blsqkfmp9wdcvg3d3fnadgr4h88eg1vngedxe4t5vavraue"
                    init={{
                      height: 500,
                      menubar: false,
                    }}
                    onEditorChange={handleEditorChange}
                  />
                  <div className="py-1" />
                  <div className="flex justify-center p-8">
                    <Field
                      className="bg-blue border text-white rounded-lg p-2 w-40 text-sm"
                      onClick={() => submitForm()}
                      disabled={isSubmitting}
                      as={'button'}
                    >
                      Add Product
                    </Field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default CreateProduct;
