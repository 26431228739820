import React, { useState } from 'react';
import DropDown3D from '../../../../common/dropdown/DropDown3D';
import { changeOrderState } from '../../../../services/client';
import { errorAlert3D } from '../../../../common/error3D';
import uuid from 'react-uuid';

export const OrderStateDropDown = ({ orderState, orderId }) => {
  const [selectedOrderStateName, setSelectedOrderStateName] = useState(
    orderState
  );

  const orderStatusList = [
    { id: uuid(), name: 'RECEIVED' },
    { id: uuid(), name: 'IN_PROGRESS' },
    { id: uuid(), name: 'SHIPPED' },
    { id: uuid(), name: 'DELIVERED' },
    { id: uuid(), name: 'COMPLETED' },
    { id: uuid(), name: 'CANCELLED' },
  ];

  const handleChange = (e) => {
    let selectedNewState = e.target.name;
    const newOrderState = {
      id: orderId,
      orderState: selectedNewState,
    };
    changeOrderState(newOrderState)
      .then(() => {
        setSelectedOrderStateName(selectedNewState);
      })
      .catch((err) => {
        errorAlert3D(err);
      });
  };

  return (
    <div>
      <DropDown3D
        list={orderStatusList}
        selectedName={selectedOrderStateName}
        handleChange={handleChange}
      />
    </div>
  );
};
