import React from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
  <div {...props}>
    <div className=" next-slick-arrow blue">
      <ArrowBackIosIcon />
    </div>
  </div>
);
export default SlickArrowRight;
