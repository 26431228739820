import React, { createContext, useEffect, useState } from 'react';
import { getNewCurrencyPrice } from '../client';

export const CurrencyContext = createContext();

const CurrencyContextProvider = (props) => {
  const [loading, isLoading] = useState(false);
  const [currencyPrice, setCurrencyPrice] = useState('');

  useEffect(() => {
    isLoading(true);
    getNewCurrencyPrice().then((res) => {
      setCurrencyPrice(res.data);
      isLoading(false);
    });
  }, []);

  return (
    <CurrencyContext.Provider
      value={{
        currencyPrice,
        loading,
      }}
    >
      {props.children}
    </CurrencyContext.Provider>
  );
};

export default CurrencyContextProvider;
