import React, { useState } from 'react';
import './ContactUs.css';
import * as yup from 'yup';
import EmailIcon from '@material-ui/icons/Email';
import Facebook from '@material-ui/icons/Facebook';
import PhoneOutlined from '@material-ui/icons/PhoneOutlined';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Button3D from '../../common/layout/Button3D';
import { errorAlert3D } from '../../common/error3D';
import { Field, Formik } from 'formik';
import { sendContactUsEmail } from '../../services/client';
import { FormikContactUsForm } from '../../common/formik/FormikFormContactUs';
import Swal from 'sweetalert2';
import Spinner3D from '../../common/spinner/Spinner3D';
import { useTranslation } from 'react-i18next';

const initialValues = {
  senderName: '',
  senderEmail: '',
  senderMobile: '',
  messageContent: '',
};

const validationSchema = yup.object({
  senderName: yup.string().required('رجاء اضافة الاسم'),
  senderMobile: yup
    .string()
    .matches(/^[0-9]{11}$/, 'رجاء اضافة رقم الموبايل الصحيح وباللغة الانكليزية')
    .required('رجاء اضافة رقم الموبايل'),
  senderEmail: yup.string().email('رجاء اضافة ايميل صحيح'),
  messageContent: yup.string().required('رجاء اضافة رسالة'),
});

function ContactUs() {
  const [loading, isLoading] = useState(false);
  const { t } = useTranslation('contact_us');
  if (loading) {
    return <Spinner3D />;
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(message, { setSubmitting, resetForm }) => {
        isLoading(true);
        sendContactUsEmail(message)
          .then(() => {
            Swal.fire(
              'لقد تم أرسال الرسالة بنجاح',
              'سوف نقوم بالرد عليك بأسرع وقت',
              'success'
            );
            isLoading(false);
            resetForm({});
            setSubmitting(false);
          })
          .catch((err) => {
            errorAlert3D(err);
            setSubmitting(false);
          });
      }}
    >
      {({ submitForm, isSubmitting, errors, touched }) => (
        <div
          className="contact body"
          style={{ width: '100%', backgroundColor: 'var(--color-background)' }}
        >
          <div className="container-contact-us">
            <div
              className="grid grid-cols-2 justify-center gap-32"
              style={{ margin: '0px', direction: 'rtl' }}
            >
              <div
                className="w-full"
                style={{ margin: '0px', direction: 'rtl' }}
              >
                <div className="pt-5 callus-contact-us">
                  <h3>{t('contact-us')}</h3>
                  <p style={{ fontSize: '14px' }}>{t('call-us')}</p>
                </div>
                <FormikContactUsForm
                  name="senderName"
                  label={t('full-name')}
                  height="2.7rem"
                  errors={errors.senderName}
                  touched={touched.senderName}
                  as={'input'}
                />
                <FormikContactUsForm
                  name="senderEmail"
                  label={t('e-mail')}
                  height="2.7rem"
                  errors={errors.senderEmail}
                  touched={touched.senderEmail}
                  as={'input'}
                />
                <FormikContactUsForm
                  name="senderMobile"
                  label={t('mobile')}
                  placeholder={'07901000000'}
                  height="2.7rem"
                  errors={errors.senderMobile}
                  touched={touched.senderMobile}
                  as={'input'}
                />
                <FormikContactUsForm
                  name="messageContent"
                  label={t('message')}
                  height="23rem"
                  errors={errors.messageContent}
                  touched={touched.messageContent}
                  as="textarea"
                />
                <div className="pt-2 form-group flex justify-content-right">
                  <Field
                    onClick={() => submitForm()}
                    disabled={isSubmitting}
                    as={Button3D}
                    type="submit"
                    text={t('send')}
                  />
                </div>
              </div>
              <div className="w-full" style={{ margin: '0px' }}>
                <div className="icons-contact-us items-center">
                  <div
                    className="mx-2 details-contact-us shadow-md pt-3"
                    style={{ backgroundColor: 'var(--color-secondary)' }}
                  >
                    <div className="flex justify-center">
                      <LocationOnIcon
                        className="locicon"
                        width="0.5rem"
                        height="0.5rem"
                        alt=""
                      />
                    </div>
                    <p
                      className="description-contact-us text-center"
                      style={{ color: ' var(--color-text)' }}
                    >
                      {t('our-location')}
                    </p>
                  </div>
                  <div
                    className="mx-2 details-contact-us shadow-md pt-3"
                    style={{ backgroundColor: 'var(--color-secondary)' }}
                  >
                    <div className="flex justify-center">
                      <PhoneOutlined
                        className="phoneicon"
                        width="0.5rem"
                        height="0.5rem"
                        alt=""
                      />
                    </div>
                    <p
                      className="description-contact-us text-center"
                      style={{ color: 'var(--color-text)' }}
                    >
                      9647712581186+
                    </p>
                  </div>
                  <div
                    className="mx-2 details-contact-us shadow-md pt-3"
                    style={{ backgroundColor: 'var(--color-secondary)' }}
                  >
                    <div className="flex justify-center">
                      <EmailIcon
                        className="emailicon"
                        width="0.5rem"
                        height="0.5rem"
                        alt=""
                      />
                    </div>
                    <p
                      className="description-contact-us text-center"
                      style={{ color: ' var(--color-text)' }}
                    >
                      3d@3d-iraq.com
                    </p>
                  </div>
                  <a href="https://www.facebook.com/3delctronics">
                    <div
                      className="mx-2 details-contact-us shadow-md pt-3"
                      style={{ backgroundColor: 'var(--color-secondary)' }}
                    >
                      <div className="flex justify-center">
                        <Facebook
                          className="emailicon"
                          width="0.5rem"
                          height="0.5rem"
                          alt=""
                        />
                      </div>

                      <p
                        className="description-contact-us text-center"
                        style={{ color: ' var(--color-text)' }}
                      >
                        3D Electronics
                      </p>
                    </div>
                  </a>
                </div>
                <iframe
                  title="mapFrame"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3334.3623284232017!2d44.44573861515159!3d33.30934178081453!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1557810930fa23bb%3A0x1cfb8cfb59e9fe19!2s3D%20elcetronics!5e0!3m2!1sen!2siq!4v1601830128513!5m2!1sen!2siq"
                  width="570rem"
                  height="700rem"
                  frameBorder="0"
                  className="w-full ml-3 xl:block hidden"
                  aria-hidden="false"
                  tabIndex="0"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}

export default ContactUs;
