import React, { useContext, useEffect, useState } from 'react';
import './ShoppingCart.css';
import { OrderContext } from '../../services/context/OrderContext';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import ShoppingCartItem from './ShoppingCartItem';
import Button3D from '../../common/layout/Button3D';
import { useTranslation } from 'react-i18next';
import { CurrencyContext } from '../../services/context/CurrencyContext';
import Spinner3D from '../../common/spinner/Spinner3D';

function ShoppingCart() {
  const { t } = useTranslation('common');
  const history = useHistory();
  const order = useContext(OrderContext);

  const [price, setPrice] = useState(0);
  const currency = useContext(CurrencyContext);

  useEffect(() => {
    if (localStorage.getItem('money') === 'dollar') {
      setPrice(order.totalPrice);
    } else {
      setPrice(order.totalPrice * currency.currencyPrice);
    }
  }, [currency, order]);

  if (order.products.length === 0) {
    return (
      <div className="justify-center text-center child">
        <p className="text-4xl pb-4"> {t('no-items-found')}</p>

        <Button3D
          onClick={() => history.goBack()}
          text={t('go-back-shopping')}
        />
      </div>
    );
  }

  if (currency.loading) {
    return <Spinner3D />;
  }

  return (
    <div
      className=" my-16 lg:flex justify-center"
      style={{ backgroundColor: 'var(--color-background)' }}
    >
      <div className="grid grid-cols-1">
        <div className="mb-3 flex justify-between px-5 py-3">
          <div>
            <Button3D
              onClick={() => history.goBack()}
              text={t('go-back-shopping')}
            />
          </div>
          <div>
            <RouterLink to="/make-order" className="mx-3">
              <Button3D text={t('buy')} />
            </RouterLink>
          </div>
        </div>
        <div className="lg:flex md:justify-end justify-center  mb-2">
          <div className="text-3xl flex justify-evenly">
            <div className="mt-1 md:px-3 flex justify-evenly">
              <div>{price.toLocaleString()}</div>
              <div className="ml-1">
                {localStorage.getItem('money') === 'dollar' ? '$' : 'IQD'}
              </div>
            </div>
            <div>{t('total-price')}</div>
          </div>
        </div>
        {order.products.map((productItem, i) => (
          <div key={i} className="col-span-1 ">
            <ShoppingCartItem productItem={productItem} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ShoppingCart;
