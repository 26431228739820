import React, { useState } from 'react';
import Cross from '@material-ui/icons/Close';
import Button3D from '../../../../common/layout/Button3D';
import { addExtraInfoOrder } from '../../../../services/client';
import { errorAlert3D } from '../../../../common/error3D';
import { closeWindowAndRefreshComponent } from '../../../../common/common3D';

const AddExtraInfoOrder = ({
  setAddExtraInfoOrderModel,
  addExtraInfoOrderModel,
  setRefreshPage,
}) => {
  const [extraInfoOrder, setExtraInfoOrder] = useState({
    id: addExtraInfoOrderModel.order.id,
    extraInfoOrder: addExtraInfoOrderModel.order.extraInfoOrder,
  });

  const addCommentToOrder = () => {
    addExtraInfoOrder(extraInfoOrder)
      .then(() => {
        closeWindowAndRefreshComponent(
          setAddExtraInfoOrderModel,
          addExtraInfoOrderModel,
          setRefreshPage
        );
      })
      .catch((err) => {
        errorAlert3D(err);
      });
  };

  return (
    <div className="font-serif w-full h-full bg-model fixed top-0 left-0 flex justify-center items-center z-40">
      <div
        className="md:w-3/5 md:h-275 model-res text-center md:rounded-lg p-4 md:relative"
        style={{ backgroundColor: 'var(--color-secondary)' }}
      >
        <div className="flex justify-end model-p text-xl">
          <Cross
            className="cursor-pointer"
            onClick={() =>
              setAddExtraInfoOrderModel(!addExtraInfoOrderModel.showModel)
            }
            width="20"
            height="20"
            alt="exit"
          />
        </div>
        <h1>Add extra info for {addExtraInfoOrderModel.order.name} order</h1>
        <textarea
          style={{ margin: '0px', direction: 'rtl' }}
          className="container mx-auto h-48 text-right text-black border border-blue-400 rounded-md"
          onChange={(e) =>
            setExtraInfoOrder({
              id: addExtraInfoOrderModel.order.id,
              extraInfoOrder: e.target.value,
            })
          }
          value={extraInfoOrder.extraInfoOrder || ''}
        />
        <Button3D text="Add to order" onClick={addCommentToOrder} />
      </div>
    </div>
  );
};

export default AddExtraInfoOrder;
