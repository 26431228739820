import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button3D from '../../../common/layout/Button3D';
import ProductList from './impl/ProductList';
import Pagination3D from '../../../common/pagination/Pagination3D';

const ProductListView = ({
  products,
  totalElements,
  setPageNumber,
  currentPage,
  itemsCountPerPage,
  searchBack,
  searchedProductName,
}) => {
  const history = useHistory();
  const { t } = useTranslation('product_cards');

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber - 1);
  };

  if (products.length === 0) {
    return (
      <div className="justify-center text-center child">
        <p className="text-4xl pb-4"> {t('no items')}</p>
        <Button3D text={t('back')} onClick={() => history.goBack()} />
      </div>
    );
  }

  return (
    <div className="md:h-full md:w-full md:pt-16 pt-10">
      <section className="overflow-hidden">
        <div className="container px-5 py-24 mx-auto">
          <div className="pb-5 flex justify-between">
            <Button3D onClick={() => history.goBack()} text={t('back')} />
            <p className="font-bold">
              {totalElements} {t('items-found')}
            </p>
          </div>
          <ProductList
            products={products}
            currentPage={currentPage}
            searchBack={searchBack}
            searchedProductName={searchedProductName}
          />
          <div className="flex md:justify-end justify-center pt-10">
            <Pagination3D
              currentPage={currentPage}
              totalElements={totalElements}
              itemsCountPerPage={itemsCountPerPage}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProductListView;
