import React, { useContext } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { LoginContext } from './services/context/LoginContext';
import AdminPage from './components/admin/AdminPage';
import CategoryContextProvider from './services/context/CategoryContext';
import CurrencyContextProvider from './services/context/CurrencyContext';

const ManagementRoutes = () => {
  const userLoggedIn = useContext(LoginContext);
  let location = useLocation();

  return (
    <div>
      {userLoggedIn.user === 'employee' || userLoggedIn.user === 'admin' ? (
        <Switch>
          <Route exact path="/admin">
            <CategoryContextProvider>
              <CurrencyContextProvider>
                <AdminPage />
              </CurrencyContextProvider>
            </CategoryContextProvider>
          </Route>
          <Redirect to="/404" />
        </Switch>
      ) : (
        <div>
          {location.pathname === '/admin' ? (
            <Redirect to="/login" />
          ) : (
            <Redirect to="/404" />
          )}
        </div>
      )}
    </div>
  );
};

export default ManagementRoutes;
