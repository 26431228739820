import React, { useContext, useState } from 'react';
import Button3D from '../../../common/layout/Button3D';
import { submitNewCurrencyPrice } from '../../../services/client';
import { errorAlert3D } from '../../../common/error3D';
import Spinner3D from '../../../common/spinner/Spinner3D';
import { CurrencyContext } from '../../../services/context/CurrencyContext';

const CurrencyTable = () => {
  const [dollarPrice, setDollarPrice] = useState('');
  const [loading, isLoading] = useState(false);
  const currency = useContext(CurrencyContext);

  const countExchange = () => {
    isLoading(true);
    submitNewCurrencyPrice(dollarPrice)
      .then((res) => {
        isLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        errorAlert3D(err);
      });
  };

  if (loading) {
    return <Spinner3D />;
  }

  return (
    <form>
      <div className="pt-16 flex justify-center items-center text-3xl">
        <div className="mr-3">Current price is:</div>
        <div>{currency.currencyPrice}</div>
      </div>
      <div className="pt-10">
        <div className="flex justify-center py-10">
          <input
            className="mr-3 px-3 shadow appearance-none border rounded w-4/12 text-white-700 text-lg h-10 w-full"
            style={{
              backgroundColor: 'var(--color-background)',
              borderRadius: 10,
            }}
            type="text"
            placeholder="Insert new currency price"
            onChange={(e) => setDollarPrice(e.target.value)}
            value={dollarPrice}
          />
        </div>
        <div className="flex justify-center">
          <Button3D
            variant="admin-main"
            onClick={countExchange}
            text="Change Price"
            type="submit"
          />
        </div>
      </div>
    </form>
  );
};

export default CurrencyTable;
