import React, { createRef } from 'react';

const Popup3D = ({ popupText, text }) => {
  const tipRef = createRef();

  function handleMouseEnter() {
    tipRef.current.style.opacity = 1;
    tipRef.current.style.marginLeft = '20px';
  }

  function handleMouseLeave() {
    tipRef.current.style.opacity = 0;
    tipRef.current.style.marginLeft = '10px';
  }

  return (
    <div
      className="relative flex justify-center"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className="absolute whitespace-no-wrap bg-gradient-to-r from-black to-gray-700 text-white ml-5  px-4 py-2 rounded"
        style={{ left: '-20px', bottom: '10px', opacity: 0 }}
        ref={tipRef}
      >
        {popupText}
        <div
          className="bg-black h-3 w-3 absolute"
          style={{ bottom: '-6px', transform: 'rotate(45deg)' }}
        />
      </div>
      {text}
    </div>
  );
};

export default Popup3D;
