import React, { useContext, useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getOneProduct } from '../../../services/client';
import { CurrencyContext } from '../../../services/context/CurrencyContext';
import Spinner3D from '../../../common/spinner/Spinner3D';
import ProductDetailsView from './impl/ProductDetailsView';

function ProductDetails() {
  const history = useHistory();
  let { productId } = useParams();
  let state = useLocation();
  let productDetails;
  let currentPage;
  let searchBack;
  let searchedProductName;
  if (state.state) {
    productDetails = state.state.productDetails;
    currentPage = state.state.currentPage;
    searchBack = state.state.searchBack;
    searchedProductName = state.state.searchedProductName;
  } else {
    productDetails = null;
    currentPage = 0;
    searchedProductName = null;
  }
  const [product, setProduct] = useState(productDetails);
  const [price, setPrice] = useState(0);
  const [assemblePrice, setAssemblePrice] = useState(0);
  const currency = useContext(CurrencyContext);

  useEffect(() => {
    // check if product already exists in the useLocation(); (page is been
    // visited as a separate url)
    if (product === null) {
      getOneProduct(productId).then((res) => {
        setProduct(res.data);
      });
    }
  }, [product, productId]);

  useEffect(() => {
    if (product) {
      if (localStorage.getItem('money') === 'dollar') {
        setPrice(product.price);
        setAssemblePrice(product.priceAssemble);
      } else {
        setPrice(product.price * currency.currencyPrice);
        setAssemblePrice(product.priceAssemble * currency.currencyPrice);
      }
    }
  }, [currency, product]);

  if (currency.loading) {
    return <Spinner3D />;
  }

  return (
    <div className="md:pt-16">
      {product && (
        <ProductDetailsView
          product={product}
          price={price}
          assemblePrice={assemblePrice}
          currentPage={currentPage}
          history={history}
          searchBack={searchBack}
          searchedProductName={searchedProductName}
        />
      )}
    </div>
  );
}

export default ProductDetails;
