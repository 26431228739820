import React, { useState } from 'react';
import Button3D from '../../../common/layout/Button3D';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SearchProducts = (id) => {
  const { t, i18n } = useTranslation('common');
  const [productsName, setProductsName] = useState('');

  const handleSubmit = (e) => {
    setProductsName('');
  };

  return (
    <form className="flex justify-even">
      <input
        className="mr-3 px-3 border rounded h-10"
        style={{
          backgroundColor: 'var(--color-background)',
          width: '80%',
        }}
        type="text"
        dir={i18n.dir()}
        placeholder={t('search-input')}
        onChange={(e) => setProductsName(e.target.value)}
        value={productsName}
      />
      <div>
        {productsName && productsName.length >= 3 ? (
          <RouterLink to={`/search-products/${productsName}`} className="mr-3">
            <Button3D
              onClick={handleSubmit}
              type="submit"
              variant="search"
              text={t('search')}
            />
          </RouterLink>
        ) : (
          <Button3D type="submit" variant="search" text={t('search')} />
        )}
      </div>
    </form>
  );
};

export default SearchProducts;
