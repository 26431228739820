import React from 'react';
import './ProductList.css';
import Product from './Product';

const ProductList = ({
  products,
  currentPage,
  searchBack,
  searchedProductName,
}) => {
  return (
    <div className="flex flex-wrap -m-4">
      {products.map((product, i) => (
        <Product
          key={i}
          product={product}
          currentPage={currentPage}
          searchBack={searchBack}
          searchedProductName={searchedProductName}
        />
      ))}
    </div>
  );
};

export default ProductList;
